'use strict';

var Caml_option = require("rescript/lib/js/caml_option.js");

function default_entity(param) {
  return {
          TAG: /* Organization_name */0,
          _0: ""
        };
}

function default_entity_type(param) {
  return /* Entity_type_organization */0;
}

function default_crawler_error(messageOpt, bodyOpt, created_atOpt, param) {
  var message = messageOpt !== undefined ? messageOpt : "";
  var body = bodyOpt !== undefined ? bodyOpt : "";
  var created_at = created_atOpt !== undefined ? Caml_option.valFromOption(created_atOpt) : undefined;
  return {
          message: message,
          body: body,
          created_at: created_at
        };
}

function default_crawler_error_list(crawlerOpt, entityOpt, errorsOpt, param) {
  var crawler = crawlerOpt !== undefined ? crawlerOpt : "";
  var entity = entityOpt !== undefined ? Caml_option.valFromOption(entityOpt) : undefined;
  var errors = errorsOpt !== undefined ? errorsOpt : /* [] */0;
  return {
          crawler: crawler,
          entity: entity,
          errors: errors
        };
}

function default_errors_request(indexOpt, queryOpt, param) {
  var index = indexOpt !== undefined ? indexOpt : "";
  var query = queryOpt !== undefined ? queryOpt : "";
  return {
          index: index,
          query: query
        };
}

function default_errors_list(errorsOpt, param) {
  var errors = errorsOpt !== undefined ? errorsOpt : /* [] */0;
  return {
          errors: errors
        };
}

function default_errors_response(param) {
  var errors = /* [] */0;
  return {
          TAG: /* Success */0,
          _0: {
            errors: errors
          }
        };
}

function default_project(full_pathOpt, param) {
  var full_path = full_pathOpt !== undefined ? full_pathOpt : "";
  return {
          full_path: full_path
        };
}

function default_add_doc_request(indexOpt, crawlerOpt, apikeyOpt, entityOpt, changesOpt, eventsOpt, projectsOpt, task_datasOpt, issuesOpt, issue_eventsOpt, errorsOpt, param) {
  var index = indexOpt !== undefined ? indexOpt : "";
  var crawler = crawlerOpt !== undefined ? crawlerOpt : "";
  var apikey = apikeyOpt !== undefined ? apikeyOpt : "";
  var entity = entityOpt !== undefined ? Caml_option.valFromOption(entityOpt) : undefined;
  var changes = changesOpt !== undefined ? changesOpt : /* [] */0;
  var events = eventsOpt !== undefined ? eventsOpt : /* [] */0;
  var projects = projectsOpt !== undefined ? projectsOpt : /* [] */0;
  var task_datas = task_datasOpt !== undefined ? task_datasOpt : /* [] */0;
  var issues = issuesOpt !== undefined ? issuesOpt : /* [] */0;
  var issue_events = issue_eventsOpt !== undefined ? issue_eventsOpt : /* [] */0;
  var errors = errorsOpt !== undefined ? errorsOpt : /* [] */0;
  return {
          index: index,
          crawler: crawler,
          apikey: apikey,
          entity: entity,
          changes: changes,
          events: events,
          projects: projects,
          task_datas: task_datas,
          issues: issues,
          issue_events: issue_events,
          errors: errors
        };
}

function default_add_doc_error(param) {
  return /* Add_unknown_index */0;
}

function default_add_doc_response(param) {
  return /* Error */{
          _0: /* Add_unknown_index */0
        };
}

function default_commit_request(indexOpt, crawlerOpt, apikeyOpt, entityOpt, timestampOpt, param) {
  var index = indexOpt !== undefined ? indexOpt : "";
  var crawler = crawlerOpt !== undefined ? crawlerOpt : "";
  var apikey = apikeyOpt !== undefined ? apikeyOpt : "";
  var entity = entityOpt !== undefined ? Caml_option.valFromOption(entityOpt) : undefined;
  var timestamp = timestampOpt !== undefined ? Caml_option.valFromOption(timestampOpt) : undefined;
  return {
          index: index,
          crawler: crawler,
          apikey: apikey,
          entity: entity,
          timestamp: timestamp
        };
}

function default_commit_error(param) {
  return /* Commit_unknown_index */0;
}

function default_commit_response(param) {
  return {
          TAG: /* Error */0,
          _0: /* Commit_unknown_index */0
        };
}

function default_commit_info_request(indexOpt, crawlerOpt, entityOpt, offsetOpt, param) {
  var index = indexOpt !== undefined ? indexOpt : "";
  var crawler = crawlerOpt !== undefined ? crawlerOpt : "";
  var entity = entityOpt !== undefined ? entityOpt : /* Entity_type_organization */0;
  var offset = offsetOpt !== undefined ? offsetOpt : 0;
  return {
          index: index,
          crawler: crawler,
          entity: entity,
          offset: offset
        };
}

function default_commit_info_error(param) {
  return /* Commit_get_unknown_index */0;
}

function default_commit_info_response_oldest_entity(entityOpt, last_commit_atOpt, param) {
  var entity = entityOpt !== undefined ? Caml_option.valFromOption(entityOpt) : undefined;
  var last_commit_at = last_commit_atOpt !== undefined ? Caml_option.valFromOption(last_commit_atOpt) : undefined;
  return {
          entity: entity,
          last_commit_at: last_commit_at
        };
}

function default_commit_info_response(param) {
  return {
          TAG: /* Error */0,
          _0: /* Commit_get_unknown_index */0
        };
}

exports.default_entity = default_entity;
exports.default_entity_type = default_entity_type;
exports.default_crawler_error = default_crawler_error;
exports.default_crawler_error_list = default_crawler_error_list;
exports.default_errors_request = default_errors_request;
exports.default_errors_list = default_errors_list;
exports.default_errors_response = default_errors_response;
exports.default_project = default_project;
exports.default_add_doc_request = default_add_doc_request;
exports.default_add_doc_error = default_add_doc_error;
exports.default_add_doc_response = default_add_doc_response;
exports.default_commit_request = default_commit_request;
exports.default_commit_error = default_commit_error;
exports.default_commit_response = default_commit_response;
exports.default_commit_info_request = default_commit_info_request;
exports.default_commit_info_error = default_commit_info_error;
exports.default_commit_info_response_oldest_entity = default_commit_info_response_oldest_entity;
exports.default_commit_info_response = default_commit_info_response;
/* No side effect */
