'use strict';

var Pbrt_bs = require("bs-ocaml-protoc-json/src/pbrt_bs.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ChangeBs$Web = require("./ChangeBs.bs.js");
var TimestampBs$Web = require("../components/TimestampBs.bs.js");

function default_issue_mutable(param) {
  return {
          id: "",
          number: 0,
          title: "",
          text: "",
          url: "",
          repository_prefix: "",
          repository_fullname: "",
          repository_shortname: "",
          author: undefined,
          created_at: undefined,
          updated_at: undefined,
          optional_closed_at: /* Closed_at */{
            _0: undefined
          },
          state: ""
        };
}

function default_issue_event_mutable(param) {
  return {
          id: "",
          created_at: undefined,
          author: undefined,
          repository_prefix: "",
          repository_fullname: "",
          repository_shortname: "",
          number: 0,
          url: "",
          type_: /* Issue_created */0
        };
}

function decode_ident(json) {
  var v = {
    uid: "",
    muid: ""
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "muid" :
          var json$1 = json["muid"];
          v.muid = Pbrt_bs.string(json$1, "ident", "muid");
          break;
      case "uid" :
          var json$2 = json["uid"];
          v.uid = Pbrt_bs.string(json$2, "ident", "uid");
          break;
      default:
        
    }
  }
  return {
          uid: v.uid,
          muid: v.muid
        };
}

function decode_issue_optional_closed_at(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("issue_optional_closed_at");
    }
    var match = keys[i];
    if (match === "closed_at") {
      var json$1 = json["closed_at"];
      return /* Closed_at */{
              _0: TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$1, "issue_optional_closed_at", "Closed_at"))
            };
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function decode_issue(json) {
  var v = default_issue_mutable(undefined);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "author" :
          var json$1 = json["author"];
          v.author = ChangeBs$Web.decode_ident(Pbrt_bs.object_(json$1, "issue", "author"));
          break;
      case "closed_at" :
          var json$2 = json["closed_at"];
          v.optional_closed_at = /* Closed_at */{
            _0: TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$2, "issue", "optional_closed_at"))
          };
          break;
      case "created_at" :
          var json$3 = json["created_at"];
          v.created_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$3, "issue", "created_at")));
          break;
      case "id" :
          var json$4 = json["id"];
          v.id = Pbrt_bs.string(json$4, "issue", "id");
          break;
      case "number" :
          var json$5 = json["number"];
          v.number = Pbrt_bs.int32(json$5, "issue", "number");
          break;
      case "repository_fullname" :
          var json$6 = json["repository_fullname"];
          v.repository_fullname = Pbrt_bs.string(json$6, "issue", "repository_fullname");
          break;
      case "repository_prefix" :
          var json$7 = json["repository_prefix"];
          v.repository_prefix = Pbrt_bs.string(json$7, "issue", "repository_prefix");
          break;
      case "repository_shortname" :
          var json$8 = json["repository_shortname"];
          v.repository_shortname = Pbrt_bs.string(json$8, "issue", "repository_shortname");
          break;
      case "state" :
          var json$9 = json["state"];
          v.state = Pbrt_bs.string(json$9, "issue", "state");
          break;
      case "text" :
          var json$10 = json["text"];
          v.text = Pbrt_bs.string(json$10, "issue", "text");
          break;
      case "title" :
          var json$11 = json["title"];
          v.title = Pbrt_bs.string(json$11, "issue", "title");
          break;
      case "updated_at" :
          var json$12 = json["updated_at"];
          v.updated_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$12, "issue", "updated_at")));
          break;
      case "url" :
          var json$13 = json["url"];
          v.url = Pbrt_bs.string(json$13, "issue", "url");
          break;
      default:
        
    }
  }
  return {
          id: v.id,
          number: v.number,
          title: v.title,
          text: v.text,
          url: v.url,
          repository_prefix: v.repository_prefix,
          repository_fullname: v.repository_fullname,
          repository_shortname: v.repository_shortname,
          author: v.author,
          created_at: v.created_at,
          updated_at: v.updated_at,
          optional_closed_at: v.optional_closed_at,
          state: v.state
        };
}

function decode_issue_commented_event(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "comment") {
      var json$1 = json["comment"];
      v = Pbrt_bs.string(json$1, "issue_commented_event", "comment");
    }
    
  }
  return {
          comment: v
        };
}

function decode_issue_event_type(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("issue_event_type");
    }
    var match = keys[i];
    switch (match) {
      case "issue_closed" :
          return /* Issue_closed */1;
      case "issue_commented" :
          var json$1 = json["issue_commented"];
          return /* Issue_commented */{
                  _0: decode_issue_commented_event(Pbrt_bs.object_(json$1, "issue_event_type", "Issue_commented"))
                };
      case "issue_created" :
          return /* Issue_created */0;
      default:
        _i = i - 1 | 0;
        continue ;
    }
  };
}

function decode_issue_event(json) {
  var v = default_issue_event_mutable(undefined);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "author" :
          var json$1 = json["author"];
          v.author = ChangeBs$Web.decode_ident(Pbrt_bs.object_(json$1, "issue_event", "author"));
          break;
      case "created_at" :
          var json$2 = json["created_at"];
          v.created_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$2, "issue_event", "created_at")));
          break;
      case "id" :
          var json$3 = json["id"];
          v.id = Pbrt_bs.string(json$3, "issue_event", "id");
          break;
      case "issue_closed" :
          v.type_ = /* Issue_closed */1;
          break;
      case "issue_commented" :
          var json$4 = json["issue_commented"];
          v.type_ = /* Issue_commented */{
            _0: decode_issue_commented_event(Pbrt_bs.object_(json$4, "issue_event", "type_"))
          };
          break;
      case "issue_created" :
          v.type_ = /* Issue_created */0;
          break;
      case "number" :
          var json$5 = json["number"];
          v.number = Pbrt_bs.int32(json$5, "issue_event", "number");
          break;
      case "repository_fullname" :
          var json$6 = json["repository_fullname"];
          v.repository_fullname = Pbrt_bs.string(json$6, "issue_event", "repository_fullname");
          break;
      case "repository_prefix" :
          var json$7 = json["repository_prefix"];
          v.repository_prefix = Pbrt_bs.string(json$7, "issue_event", "repository_prefix");
          break;
      case "repository_shortname" :
          var json$8 = json["repository_shortname"];
          v.repository_shortname = Pbrt_bs.string(json$8, "issue_event", "repository_shortname");
          break;
      case "url" :
          var json$9 = json["url"];
          v.url = Pbrt_bs.string(json$9, "issue_event", "url");
          break;
      default:
        
    }
  }
  return {
          id: v.id,
          created_at: v.created_at,
          author: v.author,
          repository_prefix: v.repository_prefix,
          repository_fullname: v.repository_fullname,
          repository_shortname: v.repository_shortname,
          number: v.number,
          url: v.url,
          type_: v.type_
        };
}

function encode_ident(v) {
  var json = {};
  json["uid"] = v.uid;
  json["muid"] = v.muid;
  return json;
}

function encode_issue_optional_closed_at(v) {
  var json = {};
  var json$p = TimestampBs$Web.encode_timestamp(v._0);
  json["closed_at"] = json$p;
  return json;
}

function encode_issue(v) {
  var json = {};
  json["id"] = v.id;
  json["number"] = v.number;
  json["title"] = v.title;
  json["text"] = v.text;
  json["url"] = v.url;
  json["repository_prefix"] = v.repository_prefix;
  json["repository_fullname"] = v.repository_fullname;
  json["repository_shortname"] = v.repository_shortname;
  var v$1 = v.author;
  if (v$1 !== undefined) {
    var json$p = ChangeBs$Web.encode_ident(v$1);
    json["author"] = json$p;
  }
  var v$2 = v.created_at;
  if (v$2 !== undefined) {
    var json$p$1 = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$2));
    json["created_at"] = json$p$1;
  }
  var v$3 = v.updated_at;
  if (v$3 !== undefined) {
    var json$p$2 = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$3));
    json["updated_at"] = json$p$2;
  }
  var v$4 = v.optional_closed_at;
  var json$p$3 = TimestampBs$Web.encode_timestamp(v$4._0);
  json["closed_at"] = json$p$3;
  json["state"] = v.state;
  return json;
}

function encode_issue_commented_event(v) {
  var json = {};
  json["comment"] = v.comment;
  return json;
}

function encode_issue_event_type(v) {
  var json = {};
  if (typeof v === "number") {
    if (v !== 0) {
      json["issue_closed"] = null;
    } else {
      json["issue_created"] = null;
    }
  } else {
    var json$p = encode_issue_commented_event(v._0);
    json["issue_commented"] = json$p;
  }
  return json;
}

function encode_issue_event(v) {
  var json = {};
  json["id"] = v.id;
  var v$1 = v.created_at;
  if (v$1 !== undefined) {
    var json$p = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$1));
    json["created_at"] = json$p;
  }
  var v$2 = v.author;
  if (v$2 !== undefined) {
    var json$p$1 = ChangeBs$Web.encode_ident(v$2);
    json["author"] = json$p$1;
  }
  json["repository_prefix"] = v.repository_prefix;
  json["repository_fullname"] = v.repository_fullname;
  json["repository_shortname"] = v.repository_shortname;
  json["number"] = v.number;
  json["url"] = v.url;
  var v$3 = v.type_;
  if (typeof v$3 === "number") {
    if (v$3 !== 0) {
      json["issue_closed"] = null;
    } else {
      json["issue_created"] = null;
    }
  } else {
    var json$p$2 = encode_issue_commented_event(v$3._0);
    json["issue_commented"] = json$p$2;
  }
  return json;
}

exports.encode_ident = encode_ident;
exports.encode_issue_optional_closed_at = encode_issue_optional_closed_at;
exports.encode_issue = encode_issue;
exports.encode_issue_commented_event = encode_issue_commented_event;
exports.encode_issue_event_type = encode_issue_event_type;
exports.encode_issue_event = encode_issue_event;
exports.decode_ident = decode_ident;
exports.decode_issue_optional_closed_at = decode_issue_optional_closed_at;
exports.decode_issue = decode_issue;
exports.decode_issue_commented_event = decode_issue_commented_event;
exports.decode_issue_event_type = decode_issue_event_type;
exports.decode_issue_event = decode_issue_event;
/* Pbrt_bs Not a pure module */
