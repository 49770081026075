'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var PFTypes = require("@softwarefactory-project/re-patternfly/src/PFTypes.bs.js");
var Belt_List = require("rescript/lib/js/belt_List.js");
var MLink$Web = require("./MLink.bs.js");
var Prelude$Web = require("./Prelude.bs.js");
var ChartjsJsx = require("./chartjs.jsx");
var PinnedChanges$Web = require("./PinnedChanges.bs.js");
var ReactCore = require("@patternfly/react-core");
var ReactIcons = require("@patternfly/react-icons");

function complexity(change) {
  return (change.changed_files_count + change.additions | 0) + change.deletions | 0;
}

function Change$TaskData$TaskTag(Props) {
  var ttype = Props.ttype;
  return React.createElement(ReactCore.Label, {
              children: Prelude$Web.str(ttype)
            });
}

var TaskTag = {
  make: Change$TaskData$TaskTag
};

function getLabelColor(level) {
  switch (level) {
    case "high" :
        return "Red";
    case "low" :
        return "Green";
    case "medium" :
        return "Orange";
    case "urgent" :
        return "Purple";
    default:
      return "Green";
  }
}

function Change$TaskData$TaskPS(Props) {
  var ps = Props.ps;
  var name = Props.name;
  if (ps === "") {
    return null;
  }
  var label = name + ": " + ps;
  return React.createElement(ReactCore.Label, {
              children: label,
              color: (function () {
                    switch (getLabelColor(ps)) {
                      case "Blue" :
                          return "blue";
                      case "Cyan" :
                          return "cyan";
                      case "Green" :
                          return "green";
                      case "Orange" :
                          return "orange";
                      case "Purple" :
                          return "purple";
                      case "Red" :
                          return "red";
                      case "Grey" :
                          return "grey";
                      
                    }
                  })()
            });
}

var TaskPS = {
  getLabelColor: getLabelColor,
  make: Change$TaskData$TaskPS
};

function Change$TaskData$TaskScore(Props) {
  var score = Props.score;
  if (score === 0) {
    return null;
  }
  var label = "Score: " + String(score);
  return React.createElement(ReactCore.Label, {
              children: label
            });
}

var TaskScore = {
  make: Change$TaskData$TaskScore
};

function getName(td) {
  var prefix = td.prefix;
  if (prefix === "") {
    return " " + td.url;
  } else if (prefix.endsWith("#")) {
    return " " + prefix + td.tid;
  } else {
    return " " + prefix;
  }
}

function Change$TaskData$TaskLink(Props) {
  var td = Props.td;
  var url = td.url;
  if (url.indexOf("show_bug.cgi") >= 0) {
    return React.createElement("a", {
                href: url
              }, React.createElement(ReactIcons.ExternalLinkAltIcon, {}), Prelude$Web.str(" rhbz#" + td.tid));
  } else {
    return React.createElement("a", {
                href: url
              }, Prelude$Web.str(getName(td)));
  }
}

var TaskLink = {
  getName: getName,
  make: Change$TaskData$TaskLink
};

function Change$TaskData(Props) {
  var td = Props.td;
  return React.createElement(React.Fragment, undefined, Prelude$Web.str("Task: "), React.createElement(Change$TaskData$TaskLink, {
                  td: td
                }), React.createElement(Change$TaskData$TaskPS, {
                  ps: td.priority,
                  name: "Priority"
                }), React.createElement(Change$TaskData$TaskPS, {
                  ps: td.severity,
                  name: "Severity"
                }), React.createElement(Change$TaskData$TaskScore, {
                  score: td.score
                }), React.createElement(ReactCore.LabelGroup, {
                  children: Belt_List.toArray(Belt_List.map(td.ttype, (function (x) {
                              return React.createElement(Change$TaskData$TaskTag, {
                                          ttype: x
                                        });
                            }))),
                  categoryName: "Tags"
                }));
}

var TaskData = {
  TaskTag: TaskTag,
  TaskPS: TaskPS,
  TaskScore: TaskScore,
  TaskLink: TaskLink,
  make: Change$TaskData
};

function Change$Approvals$Label(Props) {
  var approval = Props.approval;
  var regex = /.*-.$/;
  var color;
  switch (approval) {
    case "CHANGES_REQUESTED" :
    case "REVIEW_REQUIRED" :
        color = "Orange";
        break;
    default:
      color = regex.test(approval) ? "Red" : (
          approval.includes("+0") ? "Grey" : "Green"
        );
  }
  return React.createElement(ReactCore.Label, {
              children: approval,
              color: (function () {
                    switch (color) {
                      case "Blue" :
                          return "blue";
                      case "Cyan" :
                          return "cyan";
                      case "Green" :
                          return "green";
                      case "Orange" :
                          return "orange";
                      case "Purple" :
                          return "purple";
                      case "Red" :
                          return "red";
                      case "Grey" :
                          return "grey";
                      
                    }
                  })()
            });
}

var Label = {
  make: Change$Approvals$Label
};

function Change$Approvals(Props) {
  var approvals = Props.approvals;
  var withGroup = Props.withGroup;
  var labels = Belt_List.toArray(Belt_List.mapWithIndex(approvals, (function (idx, approval) {
              return React.createElement(Change$Approvals$Label, {
                          approval: approval,
                          key: String(idx)
                        });
            })));
  if (withGroup) {
    return React.createElement(ReactCore.LabelGroup, {
                children: labels,
                categoryName: "Approvals",
                numLabels: 5
              });
  } else {
    return labels;
  }
}

var Approvals = {
  Label: Label,
  make: Change$Approvals
};

function Change$Tags$Tag(Props) {
  var tag = Props.tag;
  return React.createElement(ReactCore.Label, {
              children: tag,
              color: "grey"
            });
}

var Tag = {
  make: Change$Tags$Tag
};

function Change$Tags(Props) {
  var tags = Props.tags;
  var withGroup = Props.withGroup;
  var tags$p = Belt_List.toArray(Belt_List.mapWithIndex(tags, (function (idx, tag) {
              return React.createElement(Change$Tags$Tag, {
                          tag: tag,
                          key: String(idx)
                        });
            })));
  if (withGroup) {
    return React.createElement(ReactCore.LabelGroup, {
                children: tags$p,
                categoryName: "Tags",
                numLabels: 5
              });
  } else {
    return tags$p;
  }
}

var Tags = {
  Tag: Tag,
  make: Change$Tags
};

function Change$Mergeable(Props) {
  var state = Props.state;
  var mergeable = Props.mergeable;
  switch (state) {
    case "CLOSED" :
    case "MERGED" :
        return null;
    default:
      if (mergeable) {
        return null;
      } else {
        return React.createElement(ReactCore.Label, {
                    children: Prelude$Web.str("Conflicting"),
                    color: "orange"
                  });
      }
  }
}

var Mergeable = {
  make: Change$Mergeable
};

function Change$FilterLink(Props) {
  var store = Props.store;
  var queryField = Props.queryField;
  var queryValue = Props.queryValue;
  var name = Props.name;
  var state = store[0];
  var newFilter = queryField + ":\"" + queryValue + "\"";
  var filter = state.filter.includes(newFilter) ? state.filter : Prelude$Web.addQuery(state.filter, newFilter);
  return React.createElement(MLink$Web.MonoLink.make, {
              store: store,
              filter: filter,
              path: "changes",
              name: name
            });
}

var FilterLink = {
  make: Change$FilterLink
};

function Change$ProjectLink(Props) {
  var store = Props.store;
  var project = Props.project;
  return React.createElement("span", {
              style: Prelude$Web.horizontalSpacing
            }, React.createElement(Change$FilterLink, {
                  store: store,
                  queryField: "repo",
                  queryValue: project,
                  name: project
                }));
}

var ProjectLink = {
  make: Change$ProjectLink
};

function Change$BranchLink(Props) {
  var store = Props.store;
  var branch = Props.branch;
  return React.createElement("span", {
              style: Prelude$Web.horizontalSpacing
            }, React.createElement(Change$FilterLink, {
                  store: store,
                  queryField: "branch",
                  queryValue: branch,
                  name: branch
                }));
}

var BranchLink = {
  make: Change$BranchLink
};

function Change$ChangeLink(Props) {
  var store = Props.store;
  var id = Props.id;
  return React.createElement(React.Fragment, undefined, Prelude$Web.str("("), React.createElement(MLink$Web.Direct.make, {
                  link: "/" + store[0].index + "/change/" + id,
                  name: "details"
                }), Prelude$Web.str(")"));
}

var ChangeLink = {
  make: Change$ChangeLink
};

function Change$AuthorLink(Props) {
  var store = Props.store;
  var title = Props.title;
  var author = Props.author;
  return React.createElement(React.Fragment, undefined, Prelude$Web.str(title), React.createElement(Change$FilterLink, {
                  store: store,
                  queryField: "author",
                  queryValue: author,
                  name: author
                }));
}

var AuthorLink = {
  make: Change$AuthorLink
};

function Change$RelativeDate(Props) {
  var title = Props.title;
  var date = Props.date;
  var dateStr = Prelude$Web.momentFromNow(date);
  return React.createElement(React.Fragment, undefined, Prelude$Web.str(title), Prelude$Web.str(dateStr));
}

var RelativeDate = {
  make: Change$RelativeDate
};

function Change$State(Props) {
  var state = Props.state;
  var draft = Props.draft;
  var match;
  switch (state) {
    case "CLOSED" :
        match = [
          "Purple",
          "Abandoned"
        ];
        break;
    case "MERGED" :
        match = [
          "Blue",
          "Merged"
        ];
        break;
    case "OPEN" :
        match = draft ? [
            "Grey",
            "Draft"
          ] : [
            "Green",
            "Open"
          ];
        break;
    default:
      match = [
        "Red",
        state
      ];
  }
  return React.createElement(ReactCore.Label, {
              children: Prelude$Web.str(match[1]),
              color: (function () {
                    switch (match[0]) {
                      case "Blue" :
                          return "blue";
                      case "Cyan" :
                          return "cyan";
                      case "Green" :
                          return "green";
                      case "Orange" :
                          return "orange";
                      case "Purple" :
                          return "purple";
                      case "Red" :
                          return "red";
                      case "Grey" :
                          return "grey";
                      
                    }
                  })()
            });
}

var State = {
  make: Change$State
};

var oneLineStyle = {
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis"
};

function Change$TaskDatas(Props) {
  var change = Props.change;
  var xs = change.task_data;
  if (xs) {
    return React.createElement(Prelude$Web.MStack.make, {
                children: Belt_List.toArray(Belt_List.mapWithIndex(xs, (function (i, td) {
                            return React.createElement(Prelude$Web.MStackItem.make, {
                                        children: React.createElement(Change$TaskData, {
                                              td: td
                                            }),
                                        key: String(i)
                                      });
                          })))
              });
  } else {
    return null;
  }
}

var TaskDatas = {
  make: Change$TaskDatas
};

function Change$StatusButton(Props) {
  var store = Props.store;
  var change = Props.change;
  var status = Props.status;
  var dispatchChange = Props.dispatchChange;
  var hide = dispatchChange[0];
  var match;
  switch (status) {
    case /* Hidden */0 :
        match = [
          "Keep this change visible",
          "Hidden status removed",
          "🧐",
          dispatchChange[1]
        ];
        break;
    case /* Visible */1 :
        match = [
          "Hide this change until it is updated",
          "Change hidden, check the settings to undo",
          "🛸",
          hide
        ];
        break;
    case /* Updated */2 :
        match = [
          "Hidden change got updated, click to hide again",
          "Change hidden again",
          "⚓",
          hide
        ];
        break;
    
  }
  var dispatch = store[1];
  var action = match[3];
  var toast = match[1];
  var onClick = function (param) {
    Curry._1(dispatch, {
          TAG: /* AddToast */11,
          _0: toast
        });
    return Curry._1(action, change);
  };
  return React.createElement(ReactCore.Tooltip, {
              children: React.createElement("a", {
                    style: {
                      paddingRight: "5px",
                      paddingLeft: "5px"
                    },
                    onClick: onClick
                  }, Prelude$Web.str(match[2])),
              content: match[0]
            });
}

var StatusButton = {
  make: Change$StatusButton
};

function Change$PinnedButton(Props) {
  var change = Props.change;
  var status = Props.status;
  var dispatchChange = Props.dispatchChange;
  var match = status ? [
      "Pin this change",
      "📌",
      dispatchChange[0]
    ] : [
      "Unpin this change",
      "⭕",
      dispatchChange[1]
    ];
  var action = match[2];
  var onClick = function (param) {
    return Curry._1(action, change);
  };
  return React.createElement(ReactCore.Tooltip, {
              children: React.createElement("a", {
                    style: {
                      paddingRight: "5px",
                      paddingLeft: "5px"
                    },
                    onClick: onClick
                  }, Prelude$Web.str(match[1])),
              content: match[0]
            });
}

var PinnedButton = {
  make: Change$PinnedButton
};

function Change$MaskButton(Props) {
  var change = Props.change;
  var status = Props.status;
  var dispatchChange = Props.dispatchChange;
  var match = status ? [
      "Mask this change. (The change will not be shown again on the board even if it got an update)",
      "👺",
      dispatchChange[0]
    ] : [
      "Unmask this change",
      "😐",
      dispatchChange[1]
    ];
  var action = match[2];
  var onClick = function (param) {
    return Curry._1(action, change);
  };
  return React.createElement(ReactCore.Tooltip, {
              children: React.createElement("a", {
                    style: {
                      paddingRight: "5px",
                      paddingLeft: "5px"
                    },
                    onClick: onClick
                  }, Prelude$Web.str(match[1])),
              content: match[0]
            });
}

var MaskButton = {
  make: Change$MaskButton
};

function Change$DataItem(Props) {
  var store = Props.store;
  var change = Props.change;
  var hiddenStatus = Props.hiddenStatus;
  var hiddenDispatchChange = Props.hiddenDispatchChange;
  var pinnedStatus = Props.pinnedStatus;
  var pinnedDispatchChange = Props.pinnedDispatchChange;
  var maskedStatus = Props.maskedStatus;
  var maskedDispatchChange = Props.maskedDispatchChange;
  return React.createElement(ReactCore.DataListItemRow, {
              children: React.createElement(ReactCore.DataListCell, {
                    children: React.createElement(ReactCore.Card, {
                          children: null,
                          isCompact: true,
                          style: PinnedChanges$Web.style(pinnedStatus)
                        }, React.createElement(ReactCore.CardHeader, {
                              children: React.createElement("span", {
                                    style: {
                                      width: "100%"
                                    }
                                  }, React.createElement(Change$State, {
                                        state: change.state,
                                        draft: change.draft
                                      }), React.createElement(Change$Mergeable, {
                                        state: change.state,
                                        mergeable: change.mergeable
                                      }), React.createElement(Change$ProjectLink, {
                                        store: store,
                                        project: change.repository_fullname
                                      }), Prelude$Web.elemText({
                                        hd: "master",
                                        tl: {
                                          hd: "main",
                                          tl: {
                                            hd: "devel",
                                            tl: /* [] */0
                                          }
                                        }
                                      }, change.target_branch) ? null : React.createElement(React.Fragment, undefined, Prelude$Web.str("<"), React.createElement(Change$BranchLink, {
                                              store: store,
                                              branch: change.target_branch
                                            }), Prelude$Web.str(">")), React.createElement(Prelude$Web.ExternalLink.make, {
                                        href: change.url,
                                        title: change.title
                                      }), React.createElement(Change$ChangeLink, {
                                        store: store,
                                        id: change.change_id
                                      }), React.createElement(Change$PinnedButton, {
                                        change: change,
                                        status: pinnedStatus,
                                        dispatchChange: pinnedDispatchChange
                                      }), React.createElement(Change$StatusButton, {
                                        store: store,
                                        change: change,
                                        status: hiddenStatus,
                                        dispatchChange: hiddenDispatchChange
                                      }), React.createElement(Change$MaskButton, {
                                        change: change,
                                        status: maskedStatus,
                                        dispatchChange: maskedDispatchChange
                                      }), React.createElement("span", {
                                        style: {
                                          float: "right"
                                        }
                                      }, Prelude$Web.str("Complexity: "), React.createElement(ReactCore.Tooltip, {
                                            children: React.createElement(ReactCore.Badge, {
                                                  children: Prelude$Web.str(String(complexity(change))),
                                                  isRead: true
                                                }),
                                            content: "Count of lines changed + Count of files changed"
                                          })))
                            }), React.createElement(ReactCore.CardBody, {
                              children: null
                            }, React.createElement("div", {
                                  style: oneLineStyle
                                }, React.createElement(Change$RelativeDate, {
                                      title: "Created ",
                                      date: Prelude$Web.getDate(change.created_at)
                                    }), React.createElement(Change$AuthorLink, {
                                      store: store,
                                      title: " by ",
                                      author: change.author
                                    }), React.createElement(Change$RelativeDate, {
                                      title: ", updated ",
                                      date: Prelude$Web.getDate(change.updated_at)
                                    })), React.createElement(Change$Approvals, {
                                  approvals: change.approval,
                                  withGroup: true
                                }), React.createElement(Change$Tags, {
                                  tags: change.labels,
                                  withGroup: true
                                }), React.createElement(Change$TaskDatas, {
                                  change: change
                                })))
                  }),
              key: change.url
            });
}

var DataItem = {
  make: Change$DataItem
};

function Change$RowItem$Head(Props) {
  return React.createElement("thead", undefined, React.createElement("tr", {
                  role: "row"
                }, React.createElement("th", {
                      role: "columnheader"
                    }, Prelude$Web.str("Title")), React.createElement("th", {
                      role: "columnheader"
                    }, Prelude$Web.str("Status")), React.createElement("th", {
                      role: "columnheader"
                    }, Prelude$Web.str("Owner")), React.createElement("th", {
                      role: "columnheader"
                    }, Prelude$Web.str("Repo")), React.createElement("th", {
                      role: "columnheader"
                    }, Prelude$Web.str("Branch")), React.createElement("th", {
                      role: "columnheader"
                    }, Prelude$Web.str("Created")), React.createElement("th", {
                      role: "columnheader"
                    }, Prelude$Web.str("Updated")), React.createElement("th", {
                      role: "columnheader"
                    }, Prelude$Web.str("Size")), React.createElement("th", {
                      role: "columnheader"
                    }, Prelude$Web.str("Approvals / Tags / Tasks"))));
}

var Head = {
  make: Change$RowItem$Head
};

function Change$RowItem(Props) {
  var store = Props.store;
  var change = Props.change;
  var hiddenStatus = Props.hiddenStatus;
  var hiddenDispatchChange = Props.hiddenDispatchChange;
  var pinnedStatus = Props.pinnedStatus;
  var pinnedDispatchChange = Props.pinnedDispatchChange;
  var maskedStatus = Props.maskedStatus;
  var maskedDispatchChange = Props.maskedDispatchChange;
  return React.createElement("tr", {
              role: "row",
              style: PinnedChanges$Web.style(pinnedStatus)
            }, React.createElement("td", {
                  role: "cell"
                }, React.createElement(Change$PinnedButton, {
                      change: change,
                      status: pinnedStatus,
                      dispatchChange: pinnedDispatchChange
                    }), React.createElement(Change$StatusButton, {
                      store: store,
                      change: change,
                      status: hiddenStatus,
                      dispatchChange: hiddenDispatchChange
                    }), React.createElement(Change$MaskButton, {
                      change: change,
                      status: maskedStatus,
                      dispatchChange: maskedDispatchChange
                    }), React.createElement(Prelude$Web.ExternalLink.make, {
                      href: change.url,
                      title: change.title
                    })), React.createElement("td", {
                  role: "cell"
                }, React.createElement("div", {
                      style: oneLineStyle
                    }, React.createElement(Change$State, {
                          state: change.state,
                          draft: change.draft
                        }), React.createElement(Change$Mergeable, {
                          state: change.state,
                          mergeable: change.mergeable
                        }))), React.createElement("td", {
                  role: "cell"
                }, React.createElement(Change$AuthorLink, {
                      store: store,
                      title: "",
                      author: change.author
                    })), React.createElement("td", {
                  role: "cell"
                }, React.createElement(Change$ProjectLink, {
                      store: store,
                      project: change.repository_fullname
                    })), React.createElement("td", {
                  role: "cell"
                }, React.createElement(Change$BranchLink, {
                      store: store,
                      branch: change.target_branch
                    })), React.createElement("td", {
                  role: "cell"
                }, React.createElement(Change$RelativeDate, {
                      title: "",
                      date: Prelude$Web.getDate(change.created_at)
                    })), React.createElement("td", {
                  role: "cell"
                }, React.createElement(Change$RelativeDate, {
                      title: "",
                      date: Prelude$Web.getDate(change.updated_at)
                    })), React.createElement("td", {
                  role: "cell"
                }, React.createElement(ReactCore.Badge, {
                      children: Prelude$Web.str(String(complexity(change))),
                      isRead: true
                    })), React.createElement("td", {
                  role: "cell"
                }, React.createElement("div", undefined, React.createElement(Change$Approvals, {
                          approvals: change.approval,
                          withGroup: true
                        })), React.createElement("div", undefined, React.createElement(Change$Tags, {
                          tags: change.labels,
                          withGroup: true
                        })), React.createElement("div", undefined, React.createElement(Change$TaskDatas, {
                          change: change
                        }))));
}

var RowItem = {
  Head: Head,
  make: Change$RowItem
};

var make = ChartjsJsx.InterweaveContent;

var InterweaveContent = {
  make: make
};

var make$1 = ChartjsJsx.TimelineGraph;

var TimelineGraph = {
  make: make$1
};

var make$2 = ChartjsJsx.CommitsTimelineGraph;

var CommitsTimelineGraph = {
  make: make$2
};

function Change$ChangeDetailView(Props) {
  var store = Props.store;
  var change = Props.change;
  var events = Props.events;
  var icon = React.createElement(ReactIcons.InfoCircleIcon, {});
  var changeTitle = React.createElement(make, {
        content: change.title
      });
  return React.createElement(Prelude$Web.MonoCard.make, {
              title: "Change details",
              tooltip_content: "Display change details",
              icon: icon,
              children: React.createElement(Prelude$Web.MStack.make, {
                    children: null
                  }, React.createElement(Prelude$Web.MStackItem.make, {
                        children: React.createElement(ReactCore.Title, {
                              children: changeTitle,
                              headingLevel: "h1"
                            })
                      }), React.createElement(Prelude$Web.MStackItem.make, {
                        children: null
                      }, React.createElement(Change$ProjectLink, {
                            store: store,
                            project: change.repository_fullname
                          }), Prelude$Web.elemText({
                            hd: "master",
                            tl: {
                              hd: "main",
                              tl: {
                                hd: "devel",
                                tl: /* [] */0
                              }
                            }
                          }, change.target_branch) ? null : React.createElement(React.Fragment, undefined, Prelude$Web.str("<"), React.createElement(Change$BranchLink, {
                                  store: store,
                                  branch: change.target_branch
                                }), Prelude$Web.str(">")), React.createElement(Prelude$Web.ExternalLink.make, {
                            href: change.url,
                            title: change.title
                          })), React.createElement(Prelude$Web.MStackItem.make, {
                        children: React.createElement(ReactCore.Grid, {
                              children: null
                            }, React.createElement(ReactCore.GridItem, {
                                  children: null,
                                  xl: PFTypes.Column._1
                                }, React.createElement(Change$Mergeable, {
                                      state: change.state,
                                      mergeable: change.mergeable
                                    }), React.createElement(Change$State, {
                                      state: change.state,
                                      draft: change.draft
                                    })), React.createElement(ReactCore.GridItem, {
                                  children: null,
                                  xl: PFTypes.Column._9
                                }, React.createElement(Change$RelativeDate, {
                                      title: "Created ",
                                      date: Prelude$Web.getDate(change.created_at)
                                    }), React.createElement(Change$AuthorLink, {
                                      store: store,
                                      title: " by ",
                                      author: change.author
                                    }), React.createElement(Change$RelativeDate, {
                                      title: ", updated ",
                                      date: Prelude$Web.getDate(change.updated_at)
                                    })))
                      }), React.createElement(Prelude$Web.MStackItem.make, {
                        children: React.createElement(Change$Approvals, {
                              approvals: change.approval,
                              withGroup: false
                            })
                      }), React.createElement(Prelude$Web.MStackItem.make, {
                        children: React.createElement(Change$Tags, {
                              tags: change.labels,
                              withGroup: false
                            })
                      }), React.createElement(Change$TaskDatas, {
                        change: change
                      }), React.createElement(Prelude$Web.MStackItem.make, {
                        children: React.createElement(ReactCore.Card, {
                              children: null
                            }, React.createElement(ReactCore.CardTitle, {
                                  children: React.createElement(ReactCore.Title, {
                                        children: "Change message",
                                        headingLevel: "h3"
                                      })
                                }), React.createElement(ReactCore.CardBody, {
                                  children: React.createElement(make, {
                                        content: change.text
                                      })
                                }))
                      }), React.createElement(Prelude$Web.MStackItem.make, {
                        children: "Complexity of " + String(complexity(change)) + " in " + Prelude$Web.int32_str(change.commits_count) + " commit(s) changing " + Prelude$Web.int32_str(change.changed_files_count) + " files(s). (" + Prelude$Web.int32_str(change.additions) + " line(s) added, " + Prelude$Web.int32_str(change.deletions) + " line(s) removed)"
                      }), React.createElement(Prelude$Web.MStackItem.make, {
                        children: React.createElement(ReactCore.Card, {
                              children: null
                            }, React.createElement(ReactCore.CardTitle, {
                                  children: React.createElement(ReactCore.Title, {
                                        children: "Changed file(s)",
                                        headingLevel: "h3"
                                      })
                                }), React.createElement(ReactCore.CardBody, {
                                  children: Belt_List.toArray(Belt_List.map(change.changed_files, (function (e) {
                                              return React.createElement("div", {
                                                          key: e.path
                                                        }, Prelude$Web.str(e.path + "(+" + Prelude$Web.int32_str(e.additions) + ",-" + Prelude$Web.int32_str(e.deletions) + ")"));
                                            })))
                                }))
                      }), React.createElement(Prelude$Web.MStackItem.make, {
                        children: React.createElement(ReactCore.Card, {
                              children: null
                            }, React.createElement(ReactCore.CardTitle, {
                                  children: React.createElement(ReactCore.Title, {
                                        children: "Change timeline",
                                        headingLevel: "h3"
                                      })
                                }), React.createElement(ReactCore.CardBody, {
                                  children: React.createElement(ReactCore.Grid, {
                                        children: null
                                      }, React.createElement(ReactCore.GridItem, {
                                            children: React.createElement(make$1, {
                                                  data: events
                                                }),
                                            xl: PFTypes.Column._6
                                          }), React.createElement(ReactCore.GridItem, {
                                            children: React.createElement(make$2, {
                                                  data: Belt_List.toArray(change.commits)
                                                }),
                                            xl: PFTypes.Column._6
                                          }))
                                }))
                      }))
            });
}

var ChangeDetailView = {
  InterweaveContent: InterweaveContent,
  TimelineGraph: TimelineGraph,
  CommitsTimelineGraph: CommitsTimelineGraph,
  make: Change$ChangeDetailView
};

exports.complexity = complexity;
exports.TaskData = TaskData;
exports.Approvals = Approvals;
exports.Tags = Tags;
exports.Mergeable = Mergeable;
exports.FilterLink = FilterLink;
exports.ProjectLink = ProjectLink;
exports.BranchLink = BranchLink;
exports.ChangeLink = ChangeLink;
exports.AuthorLink = AuthorLink;
exports.RelativeDate = RelativeDate;
exports.State = State;
exports.oneLineStyle = oneLineStyle;
exports.TaskDatas = TaskDatas;
exports.StatusButton = StatusButton;
exports.PinnedButton = PinnedButton;
exports.MaskButton = MaskButton;
exports.DataItem = DataItem;
exports.RowItem = RowItem;
exports.ChangeDetailView = ChangeDetailView;
/* make Not a pure module */
