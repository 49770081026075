'use strict';

var Axios = require("axios");
var AuthBs$Web = require("../messages/AuthBs.bs.js");
var LoginBs$Web = require("../messages/LoginBs.bs.js");
var ConfigBs$Web = require("../messages/ConfigBs.bs.js");
var MetricBs$Web = require("../messages/MetricBs.bs.js");
var SearchBs$Web = require("../messages/SearchBs.bs.js");
var CrawlerBs$Web = require("../messages/CrawlerBs.bs.js");

var serverUrl = ((window.API_URL !== '__API_URL__' ? window.API_URL : process.env.REACT_APP_API_URL || ''));

function loginValidation(request) {
  return Axios.post(serverUrl + "/api/2/login/username/validate", LoginBs$Web.encode_login_validation_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: LoginBs$Web.decode_login_validation_response(resp.data)
                        });
            });
}

var Login = {
  loginValidation: loginValidation
};

function getMagicJwt(request) {
  return Axios.post(serverUrl + "/api/2/auth/get", AuthBs$Web.encode_get_magic_jwt_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: AuthBs$Web.decode_get_magic_jwt_response(resp.data)
                        });
            });
}

function whoAmi(request) {
  return Axios.post(serverUrl + "/api/2/auth/whoami", AuthBs$Web.encode_who_ami_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: AuthBs$Web.decode_who_ami_response(resp.data)
                        });
            });
}

var Auth = {
  getMagicJwt: getMagicJwt,
  whoAmi: whoAmi
};

function getWorkspaces(request) {
  return Axios.post(serverUrl + "/api/2/get_workspaces", ConfigBs$Web.encode_get_workspaces_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: ConfigBs$Web.decode_get_workspaces_response(resp.data)
                        });
            });
}

function getProjects(request) {
  return Axios.post(serverUrl + "/api/2/get_projects", ConfigBs$Web.encode_get_projects_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: ConfigBs$Web.decode_get_projects_response(resp.data)
                        });
            });
}

function getGroups(request) {
  return Axios.post(serverUrl + "/api/2/get_groups", ConfigBs$Web.encode_get_groups_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: ConfigBs$Web.decode_get_groups_response(resp.data)
                        });
            });
}

function getGroupMembers(request) {
  return Axios.post(serverUrl + "/api/2/get_group_members", ConfigBs$Web.encode_get_group_members_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: ConfigBs$Web.decode_get_group_members_response(resp.data)
                        });
            });
}

function getAbout(request) {
  return Axios.post(serverUrl + "/api/2/about", ConfigBs$Web.encode_get_about_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: ConfigBs$Web.decode_get_about_response(resp.data)
                        });
            });
}

var Config = {
  getWorkspaces: getWorkspaces,
  getProjects: getProjects,
  getGroups: getGroups,
  getGroupMembers: getGroupMembers,
  getAbout: getAbout
};

function suggestions(request) {
  return Axios.post(serverUrl + "/api/2/suggestions", SearchBs$Web.encode_suggestions_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: SearchBs$Web.decode_suggestions_response(resp.data)
                        });
            });
}

function fields(request) {
  return Axios.post(serverUrl + "/api/2/search/fields", SearchBs$Web.encode_fields_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: SearchBs$Web.decode_fields_response(resp.data)
                        });
            });
}

function check(request) {
  return Axios.post(serverUrl + "/api/2/search/check", SearchBs$Web.encode_check_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: SearchBs$Web.decode_check_response(resp.data)
                        });
            });
}

function query(request) {
  return Axios.post(serverUrl + "/api/2/search/query", SearchBs$Web.encode_query_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: SearchBs$Web.decode_query_response(resp.data)
                        });
            });
}

function author(request) {
  return Axios.post(serverUrl + "/api/2/search/author", SearchBs$Web.encode_author_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: SearchBs$Web.decode_author_response(resp.data)
                        });
            });
}

var Search = {
  suggestions: suggestions,
  fields: fields,
  check: check,
  query: query,
  author: author
};

function list(request) {
  return Axios.post(serverUrl + "/api/2/metric/list", MetricBs$Web.encode_list_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: MetricBs$Web.decode_list_response(resp.data)
                        });
            });
}

function get(request) {
  return Axios.post(serverUrl + "/api/2/metric/get", MetricBs$Web.encode_get_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: MetricBs$Web.decode_get_response(resp.data)
                        });
            });
}

function info(request) {
  return Axios.post(serverUrl + "/api/2/metric/info", MetricBs$Web.encode_info_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: MetricBs$Web.decode_info_response(resp.data)
                        });
            });
}

var Metric = {
  list: list,
  get: get,
  info: info
};

function addDoc(request) {
  return Axios.post(serverUrl + "/api/2/crawler/add", CrawlerBs$Web.encode_add_doc_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: CrawlerBs$Web.decode_add_doc_response(resp.data)
                        });
            });
}

function commit(request) {
  return Axios.post(serverUrl + "/api/2/crawler/commit", CrawlerBs$Web.encode_commit_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: CrawlerBs$Web.decode_commit_response(resp.data)
                        });
            });
}

function commitInfo(request) {
  return Axios.post(serverUrl + "/api/2/crawler/get_commit_info", CrawlerBs$Web.encode_commit_info_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: CrawlerBs$Web.decode_commit_info_response(resp.data)
                        });
            });
}

function errors(request) {
  return Axios.post(serverUrl + "/api/2/crawler/errors", CrawlerBs$Web.encode_errors_request(request)).then(function (resp) {
              return Promise.resolve({
                          data: CrawlerBs$Web.decode_errors_response(resp.data)
                        });
            });
}

var Crawler = {
  addDoc: addDoc,
  commit: commit,
  commitInfo: commitInfo,
  errors: errors
};

exports.serverUrl = serverUrl;
exports.Login = Login;
exports.Auth = Auth;
exports.Config = Config;
exports.Search = Search;
exports.Metric = Metric;
exports.Crawler = Crawler;
/* serverUrl Not a pure module */
