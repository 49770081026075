'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Pbrt_bs = require("bs-ocaml-protoc-json/src/pbrt_bs.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var IssueBs$Web = require("./IssueBs.bs.js");
var ChangeBs$Web = require("./ChangeBs.bs.js");
var SearchBs$Web = require("./SearchBs.bs.js");
var TimestampBs$Web = require("../components/TimestampBs.bs.js");
var CrawlerTypes$Web = require("./CrawlerTypes.bs.js");

function default_add_doc_request_mutable(param) {
  return {
          index: "",
          crawler: "",
          apikey: "",
          entity: undefined,
          changes: /* [] */0,
          events: /* [] */0,
          projects: /* [] */0,
          task_datas: /* [] */0,
          issues: /* [] */0,
          issue_events: /* [] */0,
          errors: /* [] */0
        };
}

function default_commit_info_request_mutable(param) {
  return {
          index: "",
          crawler: "",
          entity: CrawlerTypes$Web.default_entity_type(undefined),
          offset: 0
        };
}

function decode_entity(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("entity");
    }
    var match = keys[i];
    switch (match) {
      case "organization_name" :
          var json$1 = json["organization_name"];
          return {
                  TAG: /* Organization_name */0,
                  _0: Pbrt_bs.string(json$1, "entity", "Organization_name")
                };
      case "project_issueName" :
          var json$2 = json["project_issueName"];
          return {
                  TAG: /* Project_issue_name */2,
                  _0: Pbrt_bs.string(json$2, "entity", "Project_issue_name")
                };
      case "project_name" :
          var json$3 = json["project_name"];
          return {
                  TAG: /* Project_name */1,
                  _0: Pbrt_bs.string(json$3, "entity", "Project_name")
                };
      case "td_name" :
          var json$4 = json["td_name"];
          return {
                  TAG: /* Td_name */3,
                  _0: Pbrt_bs.string(json$4, "entity", "Td_name")
                };
      case "user_name" :
          var json$5 = json["user_name"];
          return {
                  TAG: /* User_name */4,
                  _0: Pbrt_bs.string(json$5, "entity", "User_name")
                };
      default:
        _i = i - 1 | 0;
        continue ;
    }
  };
}

function decode_entity_type(json) {
  var match = Pbrt_bs.string(json, "entity_type", "value");
  switch (match) {
    case "" :
    case "ENTITY_TYPE_ORGANIZATION" :
        return /* Entity_type_organization */0;
    case "ENTITY_TYPE_PROJECT" :
        return /* Entity_type_project */1;
    case "ENTITY_TYPE_TASK_DATA" :
        return /* Entity_type_task_data */2;
    case "ENTITY_TYPE_USER" :
        return /* Entity_type_user */3;
    default:
      return Pbrt_bs.E.malformed_variant("entity_type");
  }
}

function decode_crawler_error(json) {
  var v = {
    message: "",
    body: "",
    created_at: undefined
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "body" :
          var json$1 = json["body"];
          v.body = Pbrt_bs.string(json$1, "crawler_error", "body");
          break;
      case "created_at" :
          var json$2 = json["created_at"];
          v.created_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$2, "crawler_error", "created_at")));
          break;
      case "message" :
          var json$3 = json["message"];
          v.message = Pbrt_bs.string(json$3, "crawler_error", "message");
          break;
      default:
        
    }
  }
  return {
          message: v.message,
          body: v.body,
          created_at: v.created_at
        };
}

function decode_crawler_error_list(json) {
  var v = {
    crawler: "",
    entity: undefined,
    errors: /* [] */0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "crawler" :
          var json$1 = json["crawler"];
          v.crawler = Pbrt_bs.string(json$1, "crawler_error_list", "crawler");
          break;
      case "entity" :
          var json$2 = json["entity"];
          v.entity = decode_entity(Pbrt_bs.object_(json$2, "crawler_error_list", "entity"));
          break;
      case "errors" :
          var a = json["errors"];
          var a$1 = Pbrt_bs.array_(a, "crawler_error_list", "errors");
          v.errors = $$Array.to_list($$Array.map((function (json) {
                      return decode_crawler_error(Pbrt_bs.object_(json, "crawler_error_list", "errors"));
                    }), a$1));
          break;
      default:
        
    }
  }
  return {
          crawler: v.crawler,
          entity: v.entity,
          errors: v.errors
        };
}

function decode_errors_request(json) {
  var v = {
    index: "",
    query: ""
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "index" :
          var json$1 = json["index"];
          v.index = Pbrt_bs.string(json$1, "errors_request", "index");
          break;
      case "query" :
          var json$2 = json["query"];
          v.query = Pbrt_bs.string(json$2, "errors_request", "query");
          break;
      default:
        
    }
  }
  return {
          index: v.index,
          query: v.query
        };
}

function decode_errors_list(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "errors") {
      var a = json["errors"];
      var a$1 = Pbrt_bs.array_(a, "errors_list", "errors");
      v = $$Array.to_list($$Array.map((function (json) {
                  return decode_crawler_error_list(Pbrt_bs.object_(json, "errors_list", "errors"));
                }), a$1));
    }
    
  }
  return {
          errors: v
        };
}

function decode_errors_response(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("errors_response");
    }
    var match = keys[i];
    switch (match) {
      case "error" :
          var json$1 = json["error"];
          return {
                  TAG: /* Error */1,
                  _0: Pbrt_bs.string(json$1, "errors_response", "Error")
                };
      case "success" :
          var json$2 = json["success"];
          return {
                  TAG: /* Success */0,
                  _0: decode_errors_list(Pbrt_bs.object_(json$2, "errors_response", "Success"))
                };
      default:
        _i = i - 1 | 0;
        continue ;
    }
  };
}

function decode_project(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "full_path") {
      var json$1 = json["full_path"];
      v = Pbrt_bs.string(json$1, "project", "full_path");
    }
    
  }
  return {
          full_path: v
        };
}

function decode_add_doc_request(json) {
  var v = default_add_doc_request_mutable(undefined);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "apikey" :
          var json$1 = json["apikey"];
          v.apikey = Pbrt_bs.string(json$1, "add_doc_request", "apikey");
          break;
      case "changes" :
          var a = json["changes"];
          var a$1 = Pbrt_bs.array_(a, "add_doc_request", "changes");
          v.changes = $$Array.to_list($$Array.map((function (json) {
                      return ChangeBs$Web.decode_change(Pbrt_bs.object_(json, "add_doc_request", "changes"));
                    }), a$1));
          break;
      case "crawler" :
          var json$2 = json["crawler"];
          v.crawler = Pbrt_bs.string(json$2, "add_doc_request", "crawler");
          break;
      case "entity" :
          var json$3 = json["entity"];
          v.entity = decode_entity(Pbrt_bs.object_(json$3, "add_doc_request", "entity"));
          break;
      case "errors" :
          var a$2 = json["errors"];
          var a$3 = Pbrt_bs.array_(a$2, "add_doc_request", "errors");
          v.errors = $$Array.to_list($$Array.map((function (json) {
                      return decode_crawler_error(Pbrt_bs.object_(json, "add_doc_request", "errors"));
                    }), a$3));
          break;
      case "events" :
          var a$4 = json["events"];
          var a$5 = Pbrt_bs.array_(a$4, "add_doc_request", "events");
          v.events = $$Array.to_list($$Array.map((function (json) {
                      return ChangeBs$Web.decode_change_event(Pbrt_bs.object_(json, "add_doc_request", "events"));
                    }), a$5));
          break;
      case "index" :
          var json$4 = json["index"];
          v.index = Pbrt_bs.string(json$4, "add_doc_request", "index");
          break;
      case "issue_events" :
          var a$6 = json["issue_events"];
          var a$7 = Pbrt_bs.array_(a$6, "add_doc_request", "issue_events");
          v.issue_events = $$Array.to_list($$Array.map((function (json) {
                      return IssueBs$Web.decode_issue_event(Pbrt_bs.object_(json, "add_doc_request", "issue_events"));
                    }), a$7));
          break;
      case "issues" :
          var a$8 = json["issues"];
          var a$9 = Pbrt_bs.array_(a$8, "add_doc_request", "issues");
          v.issues = $$Array.to_list($$Array.map((function (json) {
                      return IssueBs$Web.decode_issue(Pbrt_bs.object_(json, "add_doc_request", "issues"));
                    }), a$9));
          break;
      case "projects" :
          var a$10 = json["projects"];
          var a$11 = Pbrt_bs.array_(a$10, "add_doc_request", "projects");
          v.projects = $$Array.to_list($$Array.map((function (json) {
                      return decode_project(Pbrt_bs.object_(json, "add_doc_request", "projects"));
                    }), a$11));
          break;
      case "task_datas" :
          var a$12 = json["task_datas"];
          var a$13 = Pbrt_bs.array_(a$12, "add_doc_request", "task_datas");
          v.task_datas = $$Array.to_list($$Array.map((function (json) {
                      return SearchBs$Web.decode_task_data(Pbrt_bs.object_(json, "add_doc_request", "task_datas"));
                    }), a$13));
          break;
      default:
        
    }
  }
  return {
          index: v.index,
          crawler: v.crawler,
          apikey: v.apikey,
          entity: v.entity,
          changes: v.changes,
          events: v.events,
          projects: v.projects,
          task_datas: v.task_datas,
          issues: v.issues,
          issue_events: v.issue_events,
          errors: v.errors
        };
}

function decode_add_doc_error(json) {
  var match = Pbrt_bs.string(json, "add_doc_error", "value");
  switch (match) {
    case "AddFailed" :
        return /* Add_failed */3;
    case "AddUnknownApiKey" :
        return /* Add_unknown_api_key */2;
    case "AddUnknownCrawler" :
        return /* Add_unknown_crawler */1;
    case "" :
    case "AddUnknownIndex" :
        return /* Add_unknown_index */0;
    default:
      return Pbrt_bs.E.malformed_variant("add_doc_error");
  }
}

function decode_add_doc_response(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("add_doc_response");
    }
    var match = keys[i];
    if (match === "error") {
      var json$1 = json["error"];
      return /* Error */{
              _0: decode_add_doc_error(json$1)
            };
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function decode_commit_request(json) {
  var v = {
    index: "",
    crawler: "",
    apikey: "",
    entity: undefined,
    timestamp: undefined
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "apikey" :
          var json$1 = json["apikey"];
          v.apikey = Pbrt_bs.string(json$1, "commit_request", "apikey");
          break;
      case "crawler" :
          var json$2 = json["crawler"];
          v.crawler = Pbrt_bs.string(json$2, "commit_request", "crawler");
          break;
      case "entity" :
          var json$3 = json["entity"];
          v.entity = decode_entity(Pbrt_bs.object_(json$3, "commit_request", "entity"));
          break;
      case "index" :
          var json$4 = json["index"];
          v.index = Pbrt_bs.string(json$4, "commit_request", "index");
          break;
      case "timestamp" :
          var json$5 = json["timestamp"];
          v.timestamp = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$5, "commit_request", "timestamp")));
          break;
      default:
        
    }
  }
  return {
          index: v.index,
          crawler: v.crawler,
          apikey: v.apikey,
          entity: v.entity,
          timestamp: v.timestamp
        };
}

function decode_commit_error(json) {
  var match = Pbrt_bs.string(json, "commit_error", "value");
  switch (match) {
    case "CommitDateInferiorThanPrevious" :
        return /* Commit_date_inferior_than_previous */3;
    case "CommitDateMissing" :
        return /* Commit_date_missing */4;
    case "CommitUnknownApiKey" :
        return /* Commit_unknown_api_key */2;
    case "CommitUnknownCrawler" :
        return /* Commit_unknown_crawler */1;
    case "" :
    case "CommitUnknownIndex" :
        return /* Commit_unknown_index */0;
    default:
      return Pbrt_bs.E.malformed_variant("commit_error");
  }
}

function decode_commit_response(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("commit_response");
    }
    var match = keys[i];
    switch (match) {
      case "error" :
          var json$1 = json["error"];
          return {
                  TAG: /* Error */0,
                  _0: decode_commit_error(json$1)
                };
      case "timestamp" :
          var json$2 = json["timestamp"];
          return {
                  TAG: /* Timestamp */1,
                  _0: TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$2, "commit_response", "Timestamp"))
                };
      default:
        _i = i - 1 | 0;
        continue ;
    }
  };
}

function decode_commit_info_request(json) {
  var v = default_commit_info_request_mutable(undefined);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "crawler" :
          var json$1 = json["crawler"];
          v.crawler = Pbrt_bs.string(json$1, "commit_info_request", "crawler");
          break;
      case "entity" :
          var json$2 = json["entity"];
          v.entity = decode_entity_type(json$2);
          break;
      case "index" :
          var json$3 = json["index"];
          v.index = Pbrt_bs.string(json$3, "commit_info_request", "index");
          break;
      case "offset" :
          var json$4 = json["offset"];
          v.offset = Pbrt_bs.int32(json$4, "commit_info_request", "offset");
          break;
      default:
        
    }
  }
  return {
          index: v.index,
          crawler: v.crawler,
          entity: v.entity,
          offset: v.offset
        };
}

function decode_commit_info_error(json) {
  var match = Pbrt_bs.string(json, "commit_info_error", "value");
  switch (match) {
    case "CommitGetNoEntity" :
        return /* Commit_get_no_entity */2;
    case "CommitGetUnknownCrawler" :
        return /* Commit_get_unknown_crawler */1;
    case "" :
    case "CommitGetUnknownIndex" :
        return /* Commit_get_unknown_index */0;
    default:
      return Pbrt_bs.E.malformed_variant("commit_info_error");
  }
}

function decode_commit_info_response_oldest_entity(json) {
  var v = {
    entity: undefined,
    last_commit_at: undefined
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "entity" :
          var json$1 = json["entity"];
          v.entity = decode_entity(Pbrt_bs.object_(json$1, "commit_info_response_oldest_entity", "entity"));
          break;
      case "last_commit_at" :
          var json$2 = json["last_commit_at"];
          v.last_commit_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$2, "commit_info_response_oldest_entity", "last_commit_at")));
          break;
      default:
        
    }
  }
  return {
          entity: v.entity,
          last_commit_at: v.last_commit_at
        };
}

function decode_commit_info_response(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("commit_info_response");
    }
    var match = keys[i];
    switch (match) {
      case "entity" :
          var json$1 = json["entity"];
          return {
                  TAG: /* Entity */1,
                  _0: decode_commit_info_response_oldest_entity(Pbrt_bs.object_(json$1, "commit_info_response", "Entity"))
                };
      case "error" :
          var json$2 = json["error"];
          return {
                  TAG: /* Error */0,
                  _0: decode_commit_info_error(json$2)
                };
      default:
        _i = i - 1 | 0;
        continue ;
    }
  };
}

function encode_entity(v) {
  var json = {};
  switch (v.TAG | 0) {
    case /* Organization_name */0 :
        json["organization_name"] = v._0;
        break;
    case /* Project_name */1 :
        json["project_name"] = v._0;
        break;
    case /* Project_issue_name */2 :
        json["project_issueName"] = v._0;
        break;
    case /* Td_name */3 :
        json["td_name"] = v._0;
        break;
    case /* User_name */4 :
        json["user_name"] = v._0;
        break;
    
  }
  return json;
}

function encode_entity_type(v) {
  switch (v) {
    case /* Entity_type_organization */0 :
        return "ENTITY_TYPE_ORGANIZATION";
    case /* Entity_type_project */1 :
        return "ENTITY_TYPE_PROJECT";
    case /* Entity_type_task_data */2 :
        return "ENTITY_TYPE_TASK_DATA";
    case /* Entity_type_user */3 :
        return "ENTITY_TYPE_USER";
    
  }
}

function encode_crawler_error(v) {
  var json = {};
  json["message"] = v.message;
  json["body"] = v.body;
  var v$1 = v.created_at;
  if (v$1 !== undefined) {
    var json$p = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$1));
    json["created_at"] = json$p;
  }
  return json;
}

function encode_crawler_error_list(v) {
  var json = {};
  json["crawler"] = v.crawler;
  var v$1 = v.entity;
  if (v$1 !== undefined) {
    var json$p = encode_entity(v$1);
    json["entity"] = json$p;
  }
  var errors$p = $$Array.map(encode_crawler_error, $$Array.of_list(v.errors));
  json["errors"] = errors$p;
  return json;
}

function encode_errors_request(v) {
  var json = {};
  json["index"] = v.index;
  json["query"] = v.query;
  return json;
}

function encode_errors_list(v) {
  var json = {};
  var errors$p = $$Array.map(encode_crawler_error_list, $$Array.of_list(v.errors));
  json["errors"] = errors$p;
  return json;
}

function encode_errors_response(v) {
  var json = {};
  if (v.TAG === /* Success */0) {
    var json$p = encode_errors_list(v._0);
    json["success"] = json$p;
  } else {
    json["error"] = v._0;
  }
  return json;
}

function encode_project(v) {
  var json = {};
  json["full_path"] = v.full_path;
  return json;
}

function encode_add_doc_request(v) {
  var json = {};
  json["index"] = v.index;
  json["crawler"] = v.crawler;
  json["apikey"] = v.apikey;
  var v$1 = v.entity;
  if (v$1 !== undefined) {
    var json$p = encode_entity(v$1);
    json["entity"] = json$p;
  }
  var changes$p = $$Array.map(ChangeBs$Web.encode_change, $$Array.of_list(v.changes));
  json["changes"] = changes$p;
  var events$p = $$Array.map(ChangeBs$Web.encode_change_event, $$Array.of_list(v.events));
  json["events"] = events$p;
  var projects$p = $$Array.map(encode_project, $$Array.of_list(v.projects));
  json["projects"] = projects$p;
  var task_datas$p = $$Array.map(SearchBs$Web.encode_task_data, $$Array.of_list(v.task_datas));
  json["task_datas"] = task_datas$p;
  var issues$p = $$Array.map(IssueBs$Web.encode_issue, $$Array.of_list(v.issues));
  json["issues"] = issues$p;
  var issue_events$p = $$Array.map(IssueBs$Web.encode_issue_event, $$Array.of_list(v.issue_events));
  json["issue_events"] = issue_events$p;
  var errors$p = $$Array.map(encode_crawler_error, $$Array.of_list(v.errors));
  json["errors"] = errors$p;
  return json;
}

function encode_add_doc_error(v) {
  switch (v) {
    case /* Add_unknown_index */0 :
        return "AddUnknownIndex";
    case /* Add_unknown_crawler */1 :
        return "AddUnknownCrawler";
    case /* Add_unknown_api_key */2 :
        return "AddUnknownApiKey";
    case /* Add_failed */3 :
        return "AddFailed";
    
  }
}

function encode_add_doc_response(v) {
  var json = {};
  json["error"] = encode_add_doc_error(v._0);
  return json;
}

function encode_commit_request(v) {
  var json = {};
  json["index"] = v.index;
  json["crawler"] = v.crawler;
  json["apikey"] = v.apikey;
  var v$1 = v.entity;
  if (v$1 !== undefined) {
    var json$p = encode_entity(v$1);
    json["entity"] = json$p;
  }
  var v$2 = v.timestamp;
  if (v$2 !== undefined) {
    var json$p$1 = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$2));
    json["timestamp"] = json$p$1;
  }
  return json;
}

function encode_commit_error(v) {
  switch (v) {
    case /* Commit_unknown_index */0 :
        return "CommitUnknownIndex";
    case /* Commit_unknown_crawler */1 :
        return "CommitUnknownCrawler";
    case /* Commit_unknown_api_key */2 :
        return "CommitUnknownApiKey";
    case /* Commit_date_inferior_than_previous */3 :
        return "CommitDateInferiorThanPrevious";
    case /* Commit_date_missing */4 :
        return "CommitDateMissing";
    
  }
}

function encode_commit_response(v) {
  var json = {};
  if (v.TAG === /* Error */0) {
    json["error"] = encode_commit_error(v._0);
  } else {
    var json$p = TimestampBs$Web.encode_timestamp(v._0);
    json["timestamp"] = json$p;
  }
  return json;
}

function encode_commit_info_request(v) {
  var json = {};
  json["index"] = v.index;
  json["crawler"] = v.crawler;
  json["entity"] = encode_entity_type(v.entity);
  json["offset"] = v.offset;
  return json;
}

function encode_commit_info_error(v) {
  switch (v) {
    case /* Commit_get_unknown_index */0 :
        return "CommitGetUnknownIndex";
    case /* Commit_get_unknown_crawler */1 :
        return "CommitGetUnknownCrawler";
    case /* Commit_get_no_entity */2 :
        return "CommitGetNoEntity";
    
  }
}

function encode_commit_info_response_oldest_entity(v) {
  var json = {};
  var v$1 = v.entity;
  if (v$1 !== undefined) {
    var json$p = encode_entity(v$1);
    json["entity"] = json$p;
  }
  var v$2 = v.last_commit_at;
  if (v$2 !== undefined) {
    var json$p$1 = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$2));
    json["last_commit_at"] = json$p$1;
  }
  return json;
}

function encode_commit_info_response(v) {
  var json = {};
  if (v.TAG === /* Error */0) {
    json["error"] = encode_commit_info_error(v._0);
  } else {
    var json$p = encode_commit_info_response_oldest_entity(v._0);
    json["entity"] = json$p;
  }
  return json;
}

exports.encode_entity = encode_entity;
exports.encode_entity_type = encode_entity_type;
exports.encode_crawler_error = encode_crawler_error;
exports.encode_crawler_error_list = encode_crawler_error_list;
exports.encode_errors_request = encode_errors_request;
exports.encode_errors_list = encode_errors_list;
exports.encode_errors_response = encode_errors_response;
exports.encode_project = encode_project;
exports.encode_add_doc_request = encode_add_doc_request;
exports.encode_add_doc_error = encode_add_doc_error;
exports.encode_add_doc_response = encode_add_doc_response;
exports.encode_commit_request = encode_commit_request;
exports.encode_commit_error = encode_commit_error;
exports.encode_commit_response = encode_commit_response;
exports.encode_commit_info_request = encode_commit_info_request;
exports.encode_commit_info_error = encode_commit_info_error;
exports.encode_commit_info_response_oldest_entity = encode_commit_info_response_oldest_entity;
exports.encode_commit_info_response = encode_commit_info_response;
exports.decode_entity = decode_entity;
exports.decode_entity_type = decode_entity_type;
exports.decode_crawler_error = decode_crawler_error;
exports.decode_crawler_error_list = decode_crawler_error_list;
exports.decode_errors_request = decode_errors_request;
exports.decode_errors_list = decode_errors_list;
exports.decode_errors_response = decode_errors_response;
exports.decode_project = decode_project;
exports.decode_add_doc_request = decode_add_doc_request;
exports.decode_add_doc_error = decode_add_doc_error;
exports.decode_add_doc_response = decode_add_doc_response;
exports.decode_commit_request = decode_commit_request;
exports.decode_commit_error = decode_commit_error;
exports.decode_commit_response = decode_commit_response;
exports.decode_commit_info_request = decode_commit_info_request;
exports.decode_commit_info_error = decode_commit_info_error;
exports.decode_commit_info_response_oldest_entity = decode_commit_info_response_oldest_entity;
exports.decode_commit_info_response = decode_commit_info_response;
/* Pbrt_bs Not a pure module */
