'use strict';

var $$Array = require("rescript/lib/js/array.js");
var Pbrt_bs = require("bs-ocaml-protoc-json/src/pbrt_bs.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var ChangeTypes$Web = require("./ChangeTypes.bs.js");
var TimestampBs$Web = require("../components/TimestampBs.bs.js");

function default_change_mutable(param) {
  return {
          id: "",
          number: 0,
          change_id: "",
          title: "",
          text: "",
          url: "",
          commit_count: 0,
          additions: 0,
          deletions: 0,
          changed_files_count: 0,
          changed_files: /* [] */0,
          commits: /* [] */0,
          repository_prefix: "",
          repository_fullname: "",
          repository_shortname: "",
          author: undefined,
          optional_merged_by: /* Merged_by */{
            _0: ChangeTypes$Web.default_ident(undefined, undefined, undefined, undefined)
          },
          branch: "",
          target_branch: "",
          created_at: undefined,
          optional_merged_at: /* Merged_at */{
            _0: undefined
          },
          updated_at: undefined,
          optional_closed_at: /* Closed_at */{
            _0: undefined
          },
          state: ChangeTypes$Web.default_change_change_state(undefined),
          optional_duration: /* Duration */{
            _0: 0
          },
          mergeable: "",
          labels: /* [] */0,
          assignees: /* [] */0,
          approvals: /* [] */0,
          draft: false,
          optional_self_merged: /* Self_merged */{
            _0: false
          },
          optional_merged_commit_sha: /* Merged_commit_sha */{
            _0: ""
          }
        };
}

function default_change_event_mutable(param) {
  return {
          id: "",
          created_at: undefined,
          author: undefined,
          repository_prefix: "",
          repository_fullname: "",
          repository_shortname: "",
          branch: "",
          target_branch: "",
          number: 0,
          change_id: "",
          url: "",
          on_author: undefined,
          on_created_at: undefined,
          changed_files: /* [] */0,
          type_: /* Change_created */0,
          labels: /* [] */0,
          optional_duration: /* Duration */{
            _0: 0
          },
          draft: false,
          optional_merged_commit_sha: /* Merged_commit_sha */{
            _0: ""
          }
        };
}

function decode_ident(json) {
  var v = {
    uid: "",
    muid: "",
    groups: /* [] */0
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "groups" :
          var a = json["groups"];
          var a$1 = Pbrt_bs.array_(a, "ident", "groups");
          v.groups = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "ident", "groups");
                    }), a$1));
          break;
      case "muid" :
          var json$1 = json["muid"];
          v.muid = Pbrt_bs.string(json$1, "ident", "muid");
          break;
      case "uid" :
          var json$2 = json["uid"];
          v.uid = Pbrt_bs.string(json$2, "ident", "uid");
          break;
      default:
        
    }
  }
  return {
          uid: v.uid,
          muid: v.muid,
          groups: v.groups
        };
}

function decode_changed_file(json) {
  var v = {
    additions: 0,
    deletions: 0,
    path: ""
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "additions" :
          var json$1 = json["additions"];
          v.additions = Pbrt_bs.int32(json$1, "changed_file", "additions");
          break;
      case "deletions" :
          var json$2 = json["deletions"];
          v.deletions = Pbrt_bs.int32(json$2, "changed_file", "deletions");
          break;
      case "path" :
          var json$3 = json["path"];
          v.path = Pbrt_bs.string(json$3, "changed_file", "path");
          break;
      default:
        
    }
  }
  return {
          additions: v.additions,
          deletions: v.deletions,
          path: v.path
        };
}

function decode_changed_file_path(json) {
  var v = "";
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "path") {
      var json$1 = json["path"];
      v = Pbrt_bs.string(json$1, "changed_file_path", "path");
    }
    
  }
  return {
          path: v
        };
}

function decode_commit(json) {
  var v = {
    sha: "",
    author: undefined,
    committer: undefined,
    authored_at: undefined,
    committed_at: undefined,
    additions: 0,
    deletions: 0,
    title: ""
  };
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "additions" :
          var json$1 = json["additions"];
          v.additions = Pbrt_bs.int32(json$1, "commit", "additions");
          break;
      case "author" :
          var json$2 = json["author"];
          v.author = decode_ident(Pbrt_bs.object_(json$2, "commit", "author"));
          break;
      case "authored_at" :
          var json$3 = json["authored_at"];
          v.authored_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$3, "commit", "authored_at")));
          break;
      case "committed_at" :
          var json$4 = json["committed_at"];
          v.committed_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$4, "commit", "committed_at")));
          break;
      case "committer" :
          var json$5 = json["committer"];
          v.committer = decode_ident(Pbrt_bs.object_(json$5, "commit", "committer"));
          break;
      case "deletions" :
          var json$6 = json["deletions"];
          v.deletions = Pbrt_bs.int32(json$6, "commit", "deletions");
          break;
      case "sha" :
          var json$7 = json["sha"];
          v.sha = Pbrt_bs.string(json$7, "commit", "sha");
          break;
      case "title" :
          var json$8 = json["title"];
          v.title = Pbrt_bs.string(json$8, "commit", "title");
          break;
      default:
        
    }
  }
  return {
          sha: v.sha,
          author: v.author,
          committer: v.committer,
          authored_at: v.authored_at,
          committed_at: v.committed_at,
          additions: v.additions,
          deletions: v.deletions,
          title: v.title
        };
}

function decode_change_change_state(json) {
  var match = Pbrt_bs.string(json, "change_change_state", "value");
  switch (match) {
    case "Closed" :
        return /* Closed */2;
    case "Merged" :
        return /* Merged */1;
    case "" :
    case "Open" :
        return /* Open */0;
    default:
      return Pbrt_bs.E.malformed_variant("change_change_state");
  }
}

function decode_change_optional_merged_by(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("change_optional_merged_by");
    }
    var match = keys[i];
    if (match === "merged_by") {
      var json$1 = json["merged_by"];
      return /* Merged_by */{
              _0: decode_ident(Pbrt_bs.object_(json$1, "change_optional_merged_by", "Merged_by"))
            };
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function decode_change_optional_merged_at(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("change_optional_merged_at");
    }
    var match = keys[i];
    if (match === "merged_at") {
      var json$1 = json["merged_at"];
      return /* Merged_at */{
              _0: TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$1, "change_optional_merged_at", "Merged_at"))
            };
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function decode_change_optional_closed_at(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("change_optional_closed_at");
    }
    var match = keys[i];
    if (match === "closed_at") {
      var json$1 = json["closed_at"];
      return /* Closed_at */{
              _0: TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$1, "change_optional_closed_at", "Closed_at"))
            };
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function decode_change_optional_duration(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("change_optional_duration");
    }
    var match = keys[i];
    if (match === "duration") {
      var json$1 = json["duration"];
      return /* Duration */{
              _0: Pbrt_bs.int32(json$1, "change_optional_duration", "Duration")
            };
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function decode_change_optional_self_merged(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("change_optional_self_merged");
    }
    var match = keys[i];
    if (match === "self_merged") {
      var json$1 = json["self_merged"];
      return /* Self_merged */{
              _0: Pbrt_bs.bool(json$1, "change_optional_self_merged", "Self_merged")
            };
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function decode_change_optional_merged_commit_sha(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("change_optional_merged_commit_sha");
    }
    var match = keys[i];
    if (match === "merged_commitSha") {
      var json$1 = json["merged_commitSha"];
      return /* Merged_commit_sha */{
              _0: Pbrt_bs.string(json$1, "change_optional_merged_commit_sha", "Merged_commit_sha")
            };
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function decode_change(json) {
  var v = default_change_mutable(undefined);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "additions" :
          var json$1 = json["additions"];
          v.additions = Pbrt_bs.int32(json$1, "change", "additions");
          break;
      case "approvals" :
          var a = json["approvals"];
          var a$1 = Pbrt_bs.array_(a, "change", "approvals");
          v.approvals = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "change", "approvals");
                    }), a$1));
          break;
      case "assignees" :
          var a$2 = json["assignees"];
          var a$3 = Pbrt_bs.array_(a$2, "change", "assignees");
          v.assignees = $$Array.to_list($$Array.map((function (json) {
                      return decode_ident(Pbrt_bs.object_(json, "change", "assignees"));
                    }), a$3));
          break;
      case "author" :
          var json$2 = json["author"];
          v.author = decode_ident(Pbrt_bs.object_(json$2, "change", "author"));
          break;
      case "branch" :
          var json$3 = json["branch"];
          v.branch = Pbrt_bs.string(json$3, "change", "branch");
          break;
      case "change_id" :
          var json$4 = json["change_id"];
          v.change_id = Pbrt_bs.string(json$4, "change", "change_id");
          break;
      case "changed_files" :
          var a$4 = json["changed_files"];
          var a$5 = Pbrt_bs.array_(a$4, "change", "changed_files");
          v.changed_files = $$Array.to_list($$Array.map((function (json) {
                      return decode_changed_file(Pbrt_bs.object_(json, "change", "changed_files"));
                    }), a$5));
          break;
      case "changed_files_count" :
          var json$5 = json["changed_files_count"];
          v.changed_files_count = Pbrt_bs.int32(json$5, "change", "changed_files_count");
          break;
      case "closed_at" :
          var json$6 = json["closed_at"];
          v.optional_closed_at = /* Closed_at */{
            _0: TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$6, "change", "optional_closed_at"))
          };
          break;
      case "commit_count" :
          var json$7 = json["commit_count"];
          v.commit_count = Pbrt_bs.int32(json$7, "change", "commit_count");
          break;
      case "commits" :
          var a$6 = json["commits"];
          var a$7 = Pbrt_bs.array_(a$6, "change", "commits");
          v.commits = $$Array.to_list($$Array.map((function (json) {
                      return decode_commit(Pbrt_bs.object_(json, "change", "commits"));
                    }), a$7));
          break;
      case "created_at" :
          var json$8 = json["created_at"];
          v.created_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$8, "change", "created_at")));
          break;
      case "deletions" :
          var json$9 = json["deletions"];
          v.deletions = Pbrt_bs.int32(json$9, "change", "deletions");
          break;
      case "draft" :
          var json$10 = json["draft"];
          v.draft = Pbrt_bs.bool(json$10, "change", "draft");
          break;
      case "duration" :
          var json$11 = json["duration"];
          v.optional_duration = /* Duration */{
            _0: Pbrt_bs.int32(json$11, "change", "optional_duration")
          };
          break;
      case "id" :
          var json$12 = json["id"];
          v.id = Pbrt_bs.string(json$12, "change", "id");
          break;
      case "labels" :
          var a$8 = json["labels"];
          var a$9 = Pbrt_bs.array_(a$8, "change", "labels");
          v.labels = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "change", "labels");
                    }), a$9));
          break;
      case "mergeable" :
          var json$13 = json["mergeable"];
          v.mergeable = Pbrt_bs.string(json$13, "change", "mergeable");
          break;
      case "merged_at" :
          var json$14 = json["merged_at"];
          v.optional_merged_at = /* Merged_at */{
            _0: TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$14, "change", "optional_merged_at"))
          };
          break;
      case "merged_by" :
          var json$15 = json["merged_by"];
          v.optional_merged_by = /* Merged_by */{
            _0: decode_ident(Pbrt_bs.object_(json$15, "change", "optional_merged_by"))
          };
          break;
      case "merged_commitSha" :
          var json$16 = json["merged_commitSha"];
          v.optional_merged_commit_sha = /* Merged_commit_sha */{
            _0: Pbrt_bs.string(json$16, "change", "optional_merged_commit_sha")
          };
          break;
      case "number" :
          var json$17 = json["number"];
          v.number = Pbrt_bs.int32(json$17, "change", "number");
          break;
      case "repository_fullname" :
          var json$18 = json["repository_fullname"];
          v.repository_fullname = Pbrt_bs.string(json$18, "change", "repository_fullname");
          break;
      case "repository_prefix" :
          var json$19 = json["repository_prefix"];
          v.repository_prefix = Pbrt_bs.string(json$19, "change", "repository_prefix");
          break;
      case "repository_shortname" :
          var json$20 = json["repository_shortname"];
          v.repository_shortname = Pbrt_bs.string(json$20, "change", "repository_shortname");
          break;
      case "self_merged" :
          var json$21 = json["self_merged"];
          v.optional_self_merged = /* Self_merged */{
            _0: Pbrt_bs.bool(json$21, "change", "optional_self_merged")
          };
          break;
      case "state" :
          var json$22 = json["state"];
          v.state = decode_change_change_state(json$22);
          break;
      case "target_branch" :
          var json$23 = json["target_branch"];
          v.target_branch = Pbrt_bs.string(json$23, "change", "target_branch");
          break;
      case "text" :
          var json$24 = json["text"];
          v.text = Pbrt_bs.string(json$24, "change", "text");
          break;
      case "title" :
          var json$25 = json["title"];
          v.title = Pbrt_bs.string(json$25, "change", "title");
          break;
      case "updated_at" :
          var json$26 = json["updated_at"];
          v.updated_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$26, "change", "updated_at")));
          break;
      case "url" :
          var json$27 = json["url"];
          v.url = Pbrt_bs.string(json$27, "change", "url");
          break;
      default:
        
    }
  }
  return {
          id: v.id,
          number: v.number,
          change_id: v.change_id,
          title: v.title,
          text: v.text,
          url: v.url,
          commit_count: v.commit_count,
          additions: v.additions,
          deletions: v.deletions,
          changed_files_count: v.changed_files_count,
          changed_files: v.changed_files,
          commits: v.commits,
          repository_prefix: v.repository_prefix,
          repository_fullname: v.repository_fullname,
          repository_shortname: v.repository_shortname,
          author: v.author,
          optional_merged_by: v.optional_merged_by,
          branch: v.branch,
          target_branch: v.target_branch,
          created_at: v.created_at,
          optional_merged_at: v.optional_merged_at,
          updated_at: v.updated_at,
          optional_closed_at: v.optional_closed_at,
          state: v.state,
          optional_duration: v.optional_duration,
          mergeable: v.mergeable,
          labels: v.labels,
          assignees: v.assignees,
          approvals: v.approvals,
          draft: v.draft,
          optional_self_merged: v.optional_self_merged,
          optional_merged_commit_sha: v.optional_merged_commit_sha
        };
}

function decode_change_reviewed_event(json) {
  var v = /* [] */0;
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    if (match === "approvals") {
      var a = json["approvals"];
      var a$1 = Pbrt_bs.array_(a, "change_reviewed_event", "approvals");
      v = $$Array.to_list($$Array.map((function (json) {
                  return Pbrt_bs.string(json, "change_reviewed_event", "approvals");
                }), a$1));
    }
    
  }
  return {
          approvals: v
        };
}

function decode_change_event_type(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("change_event_type");
    }
    var match = keys[i];
    switch (match) {
      case "change_abandoned" :
          return /* Change_abandoned */2;
      case "change_commented" :
          return /* Change_commented */1;
      case "change_commitForcePushed" :
          return /* Change_commit_force_pushed */3;
      case "change_commitPushed" :
          return /* Change_commit_pushed */4;
      case "change_created" :
          return /* Change_created */0;
      case "change_merged" :
          return /* Change_merged */5;
      case "change_reviewed" :
          var json$1 = json["change_reviewed"];
          return /* Change_reviewed */{
                  _0: decode_change_reviewed_event(Pbrt_bs.object_(json$1, "change_event_type", "Change_reviewed"))
                };
      default:
        _i = i - 1 | 0;
        continue ;
    }
  };
}

function decode_change_event_optional_duration(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("change_event_optional_duration");
    }
    var match = keys[i];
    if (match === "duration") {
      var json$1 = json["duration"];
      return /* Duration */{
              _0: Pbrt_bs.int32(json$1, "change_event_optional_duration", "Duration")
            };
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function decode_change_event_optional_merged_commit_sha(json) {
  var keys = Object.keys(json);
  var _i = keys.length - 1 | 0;
  while(true) {
    var i = _i;
    if (i === -1) {
      return Pbrt_bs.E.malformed_variant("change_event_optional_merged_commit_sha");
    }
    var match = keys[i];
    if (match === "merged_commitSha") {
      var json$1 = json["merged_commitSha"];
      return /* Merged_commit_sha */{
              _0: Pbrt_bs.string(json$1, "change_event_optional_merged_commit_sha", "Merged_commit_sha")
            };
    }
    _i = i - 1 | 0;
    continue ;
  };
}

function decode_change_event(json) {
  var v = default_change_event_mutable(undefined);
  var keys = Object.keys(json);
  var last_key_index = keys.length - 1 | 0;
  for(var i = 0; i <= last_key_index; ++i){
    var match = keys[i];
    switch (match) {
      case "author" :
          var json$1 = json["author"];
          v.author = decode_ident(Pbrt_bs.object_(json$1, "change_event", "author"));
          break;
      case "branch" :
          var json$2 = json["branch"];
          v.branch = Pbrt_bs.string(json$2, "change_event", "branch");
          break;
      case "change_abandoned" :
          v.type_ = /* Change_abandoned */2;
          break;
      case "change_commented" :
          v.type_ = /* Change_commented */1;
          break;
      case "change_commitForcePushed" :
          v.type_ = /* Change_commit_force_pushed */3;
          break;
      case "change_commitPushed" :
          v.type_ = /* Change_commit_pushed */4;
          break;
      case "change_created" :
          v.type_ = /* Change_created */0;
          break;
      case "change_id" :
          var json$3 = json["change_id"];
          v.change_id = Pbrt_bs.string(json$3, "change_event", "change_id");
          break;
      case "change_merged" :
          v.type_ = /* Change_merged */5;
          break;
      case "change_reviewed" :
          var json$4 = json["change_reviewed"];
          v.type_ = /* Change_reviewed */{
            _0: decode_change_reviewed_event(Pbrt_bs.object_(json$4, "change_event", "type_"))
          };
          break;
      case "changed_files" :
          var a = json["changed_files"];
          var a$1 = Pbrt_bs.array_(a, "change_event", "changed_files");
          v.changed_files = $$Array.to_list($$Array.map((function (json) {
                      return decode_changed_file_path(Pbrt_bs.object_(json, "change_event", "changed_files"));
                    }), a$1));
          break;
      case "created_at" :
          var json$5 = json["created_at"];
          v.created_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$5, "change_event", "created_at")));
          break;
      case "draft" :
          var json$6 = json["draft"];
          v.draft = Pbrt_bs.bool(json$6, "change_event", "draft");
          break;
      case "duration" :
          var json$7 = json["duration"];
          v.optional_duration = /* Duration */{
            _0: Pbrt_bs.int32(json$7, "change_event", "optional_duration")
          };
          break;
      case "id" :
          var json$8 = json["id"];
          v.id = Pbrt_bs.string(json$8, "change_event", "id");
          break;
      case "labels" :
          var a$2 = json["labels"];
          var a$3 = Pbrt_bs.array_(a$2, "change_event", "labels");
          v.labels = $$Array.to_list($$Array.map((function (json) {
                      return Pbrt_bs.string(json, "change_event", "labels");
                    }), a$3));
          break;
      case "merged_commitSha" :
          var json$9 = json["merged_commitSha"];
          v.optional_merged_commit_sha = /* Merged_commit_sha */{
            _0: Pbrt_bs.string(json$9, "change_event", "optional_merged_commit_sha")
          };
          break;
      case "number" :
          var json$10 = json["number"];
          v.number = Pbrt_bs.int32(json$10, "change_event", "number");
          break;
      case "on_author" :
          var json$11 = json["on_author"];
          v.on_author = decode_ident(Pbrt_bs.object_(json$11, "change_event", "on_author"));
          break;
      case "on_created_at" :
          var json$12 = json["on_created_at"];
          v.on_created_at = Caml_option.some(TimestampBs$Web.decode_timestamp(Pbrt_bs.string(json$12, "change_event", "on_created_at")));
          break;
      case "repository_fullname" :
          var json$13 = json["repository_fullname"];
          v.repository_fullname = Pbrt_bs.string(json$13, "change_event", "repository_fullname");
          break;
      case "repository_prefix" :
          var json$14 = json["repository_prefix"];
          v.repository_prefix = Pbrt_bs.string(json$14, "change_event", "repository_prefix");
          break;
      case "repository_shortname" :
          var json$15 = json["repository_shortname"];
          v.repository_shortname = Pbrt_bs.string(json$15, "change_event", "repository_shortname");
          break;
      case "target_branch" :
          var json$16 = json["target_branch"];
          v.target_branch = Pbrt_bs.string(json$16, "change_event", "target_branch");
          break;
      case "url" :
          var json$17 = json["url"];
          v.url = Pbrt_bs.string(json$17, "change_event", "url");
          break;
      default:
        
    }
  }
  return {
          id: v.id,
          created_at: v.created_at,
          author: v.author,
          repository_prefix: v.repository_prefix,
          repository_fullname: v.repository_fullname,
          repository_shortname: v.repository_shortname,
          branch: v.branch,
          target_branch: v.target_branch,
          number: v.number,
          change_id: v.change_id,
          url: v.url,
          on_author: v.on_author,
          on_created_at: v.on_created_at,
          changed_files: v.changed_files,
          type_: v.type_,
          labels: v.labels,
          optional_duration: v.optional_duration,
          draft: v.draft,
          optional_merged_commit_sha: v.optional_merged_commit_sha
        };
}

function encode_ident(v) {
  var json = {};
  json["uid"] = v.uid;
  json["muid"] = v.muid;
  var a = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.groups));
  json["groups"] = a;
  return json;
}

function encode_changed_file(v) {
  var json = {};
  json["additions"] = v.additions;
  json["deletions"] = v.deletions;
  json["path"] = v.path;
  return json;
}

function encode_changed_file_path(v) {
  var json = {};
  json["path"] = v.path;
  return json;
}

function encode_commit(v) {
  var json = {};
  json["sha"] = v.sha;
  var v$1 = v.author;
  if (v$1 !== undefined) {
    var json$p = encode_ident(v$1);
    json["author"] = json$p;
  }
  var v$2 = v.committer;
  if (v$2 !== undefined) {
    var json$p$1 = encode_ident(v$2);
    json["committer"] = json$p$1;
  }
  var v$3 = v.authored_at;
  if (v$3 !== undefined) {
    var json$p$2 = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$3));
    json["authored_at"] = json$p$2;
  }
  var v$4 = v.committed_at;
  if (v$4 !== undefined) {
    var json$p$3 = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$4));
    json["committed_at"] = json$p$3;
  }
  json["additions"] = v.additions;
  json["deletions"] = v.deletions;
  json["title"] = v.title;
  return json;
}

function encode_change_change_state(v) {
  switch (v) {
    case /* Open */0 :
        return "Open";
    case /* Merged */1 :
        return "Merged";
    case /* Closed */2 :
        return "Closed";
    
  }
}

function encode_change_optional_merged_by(v) {
  var json = {};
  var json$p = encode_ident(v._0);
  json["merged_by"] = json$p;
  return json;
}

function encode_change_optional_merged_at(v) {
  var json = {};
  var json$p = TimestampBs$Web.encode_timestamp(v._0);
  json["merged_at"] = json$p;
  return json;
}

function encode_change_optional_closed_at(v) {
  var json = {};
  var json$p = TimestampBs$Web.encode_timestamp(v._0);
  json["closed_at"] = json$p;
  return json;
}

function encode_change_optional_duration(v) {
  var json = {};
  json["duration"] = v._0;
  return json;
}

function encode_change_optional_self_merged(v) {
  var json = {};
  json["self_merged"] = v._0;
  return json;
}

function encode_change_optional_merged_commit_sha(v) {
  var json = {};
  json["merged_commitSha"] = v._0;
  return json;
}

function encode_change(v) {
  var json = {};
  json["id"] = v.id;
  json["number"] = v.number;
  json["change_id"] = v.change_id;
  json["title"] = v.title;
  json["text"] = v.text;
  json["url"] = v.url;
  json["commit_count"] = v.commit_count;
  json["additions"] = v.additions;
  json["deletions"] = v.deletions;
  json["changed_files_count"] = v.changed_files_count;
  var changed_files$p = $$Array.map(encode_changed_file, $$Array.of_list(v.changed_files));
  json["changed_files"] = changed_files$p;
  var commits$p = $$Array.map(encode_commit, $$Array.of_list(v.commits));
  json["commits"] = commits$p;
  json["repository_prefix"] = v.repository_prefix;
  json["repository_fullname"] = v.repository_fullname;
  json["repository_shortname"] = v.repository_shortname;
  var v$1 = v.author;
  if (v$1 !== undefined) {
    var json$p = encode_ident(v$1);
    json["author"] = json$p;
  }
  var v$2 = v.optional_merged_by;
  var json$p$1 = encode_ident(v$2._0);
  json["merged_by"] = json$p$1;
  json["branch"] = v.branch;
  json["target_branch"] = v.target_branch;
  var v$3 = v.created_at;
  if (v$3 !== undefined) {
    var json$p$2 = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$3));
    json["created_at"] = json$p$2;
  }
  var v$4 = v.optional_merged_at;
  var json$p$3 = TimestampBs$Web.encode_timestamp(v$4._0);
  json["merged_at"] = json$p$3;
  var v$5 = v.updated_at;
  if (v$5 !== undefined) {
    var json$p$4 = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$5));
    json["updated_at"] = json$p$4;
  }
  var v$6 = v.optional_closed_at;
  var json$p$5 = TimestampBs$Web.encode_timestamp(v$6._0);
  json["closed_at"] = json$p$5;
  json["state"] = encode_change_change_state(v.state);
  var v$7 = v.optional_duration;
  json["duration"] = v$7._0;
  json["mergeable"] = v.mergeable;
  var a = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.labels));
  json["labels"] = a;
  var assignees$p = $$Array.map(encode_ident, $$Array.of_list(v.assignees));
  json["assignees"] = assignees$p;
  var a$1 = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.approvals));
  json["approvals"] = a$1;
  json["draft"] = v.draft;
  var v$8 = v.optional_self_merged;
  json["self_merged"] = v$8._0;
  var v$9 = v.optional_merged_commit_sha;
  json["merged_commitSha"] = v$9._0;
  return json;
}

function encode_change_reviewed_event(v) {
  var json = {};
  var a = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.approvals));
  json["approvals"] = a;
  return json;
}

function encode_change_event_type(v) {
  var json = {};
  if (typeof v === "number") {
    switch (v) {
      case /* Change_created */0 :
          json["change_created"] = null;
          break;
      case /* Change_commented */1 :
          json["change_commented"] = null;
          break;
      case /* Change_abandoned */2 :
          json["change_abandoned"] = null;
          break;
      case /* Change_commit_force_pushed */3 :
          json["change_commitForcePushed"] = null;
          break;
      case /* Change_commit_pushed */4 :
          json["change_commitPushed"] = null;
          break;
      case /* Change_merged */5 :
          json["change_merged"] = null;
          break;
      
    }
  } else {
    var json$p = encode_change_reviewed_event(v._0);
    json["change_reviewed"] = json$p;
  }
  return json;
}

function encode_change_event_optional_duration(v) {
  var json = {};
  json["duration"] = v._0;
  return json;
}

function encode_change_event_optional_merged_commit_sha(v) {
  var json = {};
  json["merged_commitSha"] = v._0;
  return json;
}

function encode_change_event(v) {
  var json = {};
  json["id"] = v.id;
  var v$1 = v.created_at;
  if (v$1 !== undefined) {
    var json$p = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$1));
    json["created_at"] = json$p;
  }
  var v$2 = v.author;
  if (v$2 !== undefined) {
    var json$p$1 = encode_ident(v$2);
    json["author"] = json$p$1;
  }
  json["repository_prefix"] = v.repository_prefix;
  json["repository_fullname"] = v.repository_fullname;
  json["repository_shortname"] = v.repository_shortname;
  json["branch"] = v.branch;
  json["target_branch"] = v.target_branch;
  json["number"] = v.number;
  json["change_id"] = v.change_id;
  json["url"] = v.url;
  var v$3 = v.on_author;
  if (v$3 !== undefined) {
    var json$p$2 = encode_ident(v$3);
    json["on_author"] = json$p$2;
  }
  var v$4 = v.on_created_at;
  if (v$4 !== undefined) {
    var json$p$3 = TimestampBs$Web.encode_timestamp(Caml_option.valFromOption(v$4));
    json["on_created_at"] = json$p$3;
  }
  var changed_files$p = $$Array.map(encode_changed_file_path, $$Array.of_list(v.changed_files));
  json["changed_files"] = changed_files$p;
  var v$5 = v.type_;
  if (typeof v$5 === "number") {
    switch (v$5) {
      case /* Change_created */0 :
          json["change_created"] = null;
          break;
      case /* Change_commented */1 :
          json["change_commented"] = null;
          break;
      case /* Change_abandoned */2 :
          json["change_abandoned"] = null;
          break;
      case /* Change_commit_force_pushed */3 :
          json["change_commitForcePushed"] = null;
          break;
      case /* Change_commit_pushed */4 :
          json["change_commitPushed"] = null;
          break;
      case /* Change_merged */5 :
          json["change_merged"] = null;
          break;
      
    }
  } else {
    var json$p$4 = encode_change_reviewed_event(v$5._0);
    json["change_reviewed"] = json$p$4;
  }
  var a = $$Array.map((function (prim) {
          return prim;
        }), $$Array.of_list(v.labels));
  json["labels"] = a;
  var v$6 = v.optional_duration;
  json["duration"] = v$6._0;
  json["draft"] = v.draft;
  var v$7 = v.optional_merged_commit_sha;
  json["merged_commitSha"] = v$7._0;
  return json;
}

exports.encode_ident = encode_ident;
exports.encode_changed_file = encode_changed_file;
exports.encode_changed_file_path = encode_changed_file_path;
exports.encode_commit = encode_commit;
exports.encode_change_change_state = encode_change_change_state;
exports.encode_change_optional_merged_by = encode_change_optional_merged_by;
exports.encode_change_optional_merged_at = encode_change_optional_merged_at;
exports.encode_change_optional_closed_at = encode_change_optional_closed_at;
exports.encode_change_optional_duration = encode_change_optional_duration;
exports.encode_change_optional_self_merged = encode_change_optional_self_merged;
exports.encode_change_optional_merged_commit_sha = encode_change_optional_merged_commit_sha;
exports.encode_change = encode_change;
exports.encode_change_reviewed_event = encode_change_reviewed_event;
exports.encode_change_event_type = encode_change_event_type;
exports.encode_change_event_optional_duration = encode_change_event_optional_duration;
exports.encode_change_event_optional_merged_commit_sha = encode_change_event_optional_merged_commit_sha;
exports.encode_change_event = encode_change_event;
exports.decode_ident = decode_ident;
exports.decode_changed_file = decode_changed_file;
exports.decode_changed_file_path = decode_changed_file_path;
exports.decode_commit = decode_commit;
exports.decode_change_change_state = decode_change_change_state;
exports.decode_change_optional_merged_by = decode_change_optional_merged_by;
exports.decode_change_optional_merged_at = decode_change_optional_merged_at;
exports.decode_change_optional_closed_at = decode_change_optional_closed_at;
exports.decode_change_optional_duration = decode_change_optional_duration;
exports.decode_change_optional_self_merged = decode_change_optional_self_merged;
exports.decode_change_optional_merged_commit_sha = decode_change_optional_merged_commit_sha;
exports.decode_change = decode_change;
exports.decode_change_reviewed_event = decode_change_reviewed_event;
exports.decode_change_event_type = decode_change_event_type;
exports.decode_change_event_optional_duration = decode_change_event_optional_duration;
exports.decode_change_event_optional_merged_commit_sha = decode_change_event_optional_merged_commit_sha;
exports.decode_change_event = decode_change_event;
/* Pbrt_bs Not a pure module */
